import * as Sentry from '@sentry/react';
import * as SentryCore from '@sentry/core';
import axios from 'axios';
import { useCallback, useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { useIdentity } from './providers/IdentityProvider';

Sentry.init({
  debug: false,
  dsn: 'https://b890e8b52b274a48aeff40a10177b5be@o1409683.ingest.sentry.io/4504208406872064',
  tunnel: 'https://sentry.allbin.se',
  replaysSessionSampleRate: 0.01,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  enabled: import.meta.env.MODE !== 'development',

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.01,
});

const heartbeatInterval = 1 * 60 * 1000; // 1 minute
const heartbeatWarningLimit = 5 * 60; // 5 minutes
const heartbeatErrorLimit = 30 * 60; // 30 minutes

function heartbeat(id: string): void {
  Sentry.addBreadcrumb({
    message: 'Heartbeat',
    category: 'heartbeat',
    level: 'info',
  });

  SentryCore.captureCheckIn({
    monitorSlug: 'heartbeats',
    status: 'ok',
  });
  console.log('heartbeat', id);

  void axios
    .post(
      `https://viu.heartbeat.sh/beat/${id}?warning=${heartbeatWarningLimit}&error=${heartbeatErrorLimit}`,
      null,
      {
        timeout: 2000,
      },
    )
    .catch(() => {
      console.log('heartbeat failed');
    });
}

export const useSentry = (): void => {
  const { id } = useIdentity(useCallback(({ id }) => ({ id }), []));
  useEffect(() => {
    if (!id || import.meta.env.MODE === 'development') return;

    Sentry.setUser({ id });

    console.log('registering heartbeat interval', id);
    heartbeat(id);
    const timeout = setInterval(() => heartbeat(id), heartbeatInterval);

    return () => {
      clearInterval(timeout);
      console.log('unregistering heartbeat interval', id);
    };
  }, [id]);
};
