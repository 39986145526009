import React from 'react';

interface Props {
  className?: string;
  header: React.ReactNode;
  children?: React.ReactNode;
  contentClassName?: string;
}

export const ContentBox: React.FC<Props> = ({
  className,
  header,
  children,
  contentClassName,
}) => {
  return (
    <div
      className={`flex flex-col rounded border border-gray-medium ${
        className || ''
      }`}
    >
      <header className="flex items-center gap-4 bg-gray-dark px-4 py-2 text-white body2">
        {header}
      </header>
      <section className={contentClassName}>{children}</section>
    </div>
  );
};

export default ContentBox;
