import { ApiLocation } from '@allbin/viu-api-client';
import { Metadata, reviveMeta } from './metadata';

export const reviveLocation = (location: ApiLocation): Location => ({
  ...location,
  meta: reviveMeta(location.meta),
});

export interface Location extends Omit<ApiLocation, 'meta'> {
  meta: Metadata;
}
