import React, { forwardRef, useEffect, useRef } from 'react';
import { TenantGrouping, TenantListSortOption } from '../../utils/TenantUtils';
import TenantListItem from './TenantListItem';
import { TenantWithFloor } from '../../types/unit';

interface TenantListProps {
  tenantGroups: TenantGrouping[];
  /** Scrolls to TenantGrouping in tenantGroups list based on value */
  scrollTo?: string;
  sort?: TenantListSortOption;
  onListItemClick?: (tenant: TenantWithFloor) => void;
  addRightPadding?: boolean;
}

const TenantList = forwardRef<HTMLDivElement, TenantListProps>(
  (
    { tenantGroups = [], sort, scrollTo, onListItemClick, addRightPadding },
    ref,
  ) => {
    const groupRefs = useRef<Record<string, HTMLDivElement>>({});

    useEffect(() => {
      if (!scrollTo || !groupRefs.current[scrollTo]) {
        return;
      }

      groupRefs.current[scrollTo].scrollIntoView({ behavior: 'smooth' });
    }, [scrollTo]);

    return (
      <div
        className={`ml-12 flex grow flex-col gap-8 overflow-auto pb-8 ${
          addRightPadding ? 'pr-12' : ''
        }`}
        ref={ref}
        id="tenantList"
      >
        {tenantGroups.map((tGroup) => (
          <section
            className="flex flex-col items-start gap-4"
            key={tGroup.group}
            ref={(el: HTMLDivElement) => {
              groupRefs.current[tGroup.group] = el;
            }}
          >
            <h2 className="pt-2 text-center text-[36px]">{tGroup.group}</h2>
            <ul className="mr-2 grid w-full auto-rows-max grid-cols-2 gap-4 overflow-hidden">
              {tGroup.tenants.map((tenant) => {
                return (
                  <TenantListItem
                    key={tenant.id}
                    tenant={tenant}
                    sort={sort}
                    onClick={onListItemClick}
                  />
                );
              })}
            </ul>
          </section>
        ))}
      </div>
    );
  },
);

export default TenantList;
