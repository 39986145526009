import React, { useMemo } from 'react';
import { useAttachments } from '../../hooks/useAttachments';
import { Attachment } from '../../types/attachments';
import AttachmentCacheItem from './AttachmentCacheItem';

const AttachmentCache: React.FC = () => {
  const { data: attachments } = useAttachments();

  const merged = useMemo<Attachment[]>(() => {
    if (!attachments) {
      return [];
    }

    return [
      ...Object.values(attachments['property-info']),
      ...Object.values(attachments['energy-declaration-ovk']),
      ...Object.values(attachments['restaurant-menu']),
      ...Object.values(attachments['other']),
    ];
  }, [attachments]);

  return (
    <>
      {merged.map((attachment) => (
        <AttachmentCacheItem key={attachment.id} attachment={attachment} />
      ))}
    </>
  );
};

export default AttachmentCache;
