import React, { FC, ReactNode } from 'react';
import { LocaleProvider } from './LocaleProvider';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ConfigProvider } from './ConfigProvider';
import PublicTransitUpdater from './PublicTransitUpdater';

const Providers: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <ConfigProvider>
      <LocaleProvider>
        <PublicTransitUpdater>{children}</PublicTransitUpdater>
        <ReactQueryDevtools initialIsOpen={false} position="right" />
      </LocaleProvider>
    </ConfigProvider>
  );
};

export default Providers;
