import React from 'react';
import { Weather, WeatherIcon } from '../../hooks/weather/weather';
import { FormattedMessage } from 'react-intl';
import { IconArrowDown } from '@allbin/icons';

interface Props {
  weather: Weather;
}

export const WeatherWidget: React.FC<Props> = ({ weather }) => {
  return (
    <div className="flex flex-col items-center justify-center gap-8">
      <WeatherIcon symbol={weather.symbol} className="h-24 w-24" />
      <div className="flex flex-col gap-4">
        <p className="text-center text-gray-medium caption">
          <FormattedMessage defaultMessage="Temperatur" />
        </p>
        <h2 className="text-center">{weather.temperature}&deg;</h2>
      </div>
      <div className="flex flex-col gap-4">
        <p className="text-center text-gray-medium caption">
          <FormattedMessage defaultMessage="Vind" /> (m/s)
        </p>
        <h3 className="flex items-center justify-center">
          {weather.windSpeed}{' '}
          <span
            style={{
              transform: `rotate(${weather.windDirection}deg)`,
            }}
          >
            <IconArrowDown className={`h-8 w-8`} />
          </span>
        </h3>
      </div>
    </div>
  );
};

export default WeatherWidget;
