import React, { FC } from 'react';
import { prettifyTime } from '../utils/helpers';
import { BookingEvent } from '../types/bookableResource';

interface Props {
  events: BookingEvent[];
}
export const EventsTimeline: FC<Props> = ({ events }) => {
  const rangeStart = 7;
  const rangeEnd = 18;
  const rangeHours = rangeEnd - rangeStart;
  const now = new Date();
  const nowTop = toPercent(toDecimal(now, rangeStart, rangeEnd), rangeHours);

  return (
    <div className="relative mr-10 flex w-20 grow flex-col justify-between">
      {Array.from({ length: rangeHours + 1 }, (_, i) => i + rangeStart).map(
        (hour) => (
          <div key={hour} className="relative w-full ">
            <p className="absolute -right-2 -translate-y-1/2 translate-x-full">
              {hour}:00
            </p>
            <div className="h-[2px] w-full bg-gray-300" />
          </div>
        ),
      )}
      {events.map(
        ({
          contact_primary,
          contact_secondary,
          contact_tertiary,
          from,
          to,
        }) => {
          const start = new Date(from);
          const end = new Date(to);
          const startNumber = toDecimal(start, rangeStart, rangeEnd);
          const endNumber = toDecimal(end, rangeStart, rangeEnd);
          const top = toPercent(startNumber, rangeHours);
          const height = toPercent(endNumber - startNumber, rangeHours);

          let bgColor = 'bg-red-300';
          let color = 'text-gray-800';
          if (now > end) {
            bgColor = 'bg-gray-100';
            color = 'text-gray-400';
          } else if (now > start) {
            bgColor = 'bg-red-500';
          }

          return (
            <div
              key={from}
              className={`absolute w-full px-[5px] py-[1px]`}
              style={{ top: `${top}%`, height: `${height}%` }}
            >
              <div
                className={`h-full w-full rounded ${bgColor} ${color} overflow-hidden px-1 py-[1px]`}
              >
                <TextLine text={contact_primary} />
                <TextLine text={contact_secondary} />
                <TextLine text={contact_tertiary} />
              </div>
            </div>
          );
        },
      )}
      {nowTop > 0 && nowTop < 100 ? (
        <div
          className="absolute h-[2px] w-full -translate-y-[1px] bg-blue"
          style={{ top: `${nowTop}%` }}
        >
          <p className="absolute left-full top-[1px] -translate-y-1/2 rounded bg-blue px-1 text-white">
            {prettifyTime(now)}
          </p>
        </div>
      ) : null}
    </div>
  );
};

interface TextLineProps {
  text?: string;
}
const TextLine: FC<TextLineProps> = ({ text }) => {
  if (!text) {
    return null;
  }

  return (
    <p className="min-h-max overflow-hidden text-ellipsis whitespace-nowrap text-xs leading-3">
      {text}
    </p>
  );
};

const toDecimal = (date: Date, rangeStart: number, rangeEnd: number): number =>
  Math.min(Math.max(date.getHours(), rangeStart), rangeEnd) -
  rangeStart +
  date.getMinutes() / 60;

const toPercent = (value: number, rangeLength: number): number =>
  (value / rangeLength) * 100;
