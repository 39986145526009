import React, { ReactNode } from 'react';
import brfgrindstugan_logo from '../brand/brfgrindstugan/brand-logo.webp';

import brfdagsmejan_logo from '../brand/brfdagsmejan/brand-logo.png';

import newsec_logo from '../brand/newsec/brand-logo.svg';

import wallenstam_logo from '../brand/wallenstam/brand-logo.svg';
import wallenstam_navbar from '../brand/wallenstam/navbar-background.webp';

import genova_logo from '../brand/genova/brand-logo.webp';
import genova_navbar from '../brand/genova/navbar-background.webp';

import cbre_logo from '../brand/cbre/brand-logo.webp';
import cbre_navbar from '../brand/cbre/navbar-background.webp';

import allbinary_logo from '../icons/company_logo_placeholder.svg';

import resona_logo from '../brand/resona/brand-logo.svg';
import resona_navbar from '../brand/resona/navbar-background.webp';

import trosso_logo from '../brand/trosso/brand-logo.png';

import kruthusen_logo from '../brand/kruthusen/brand-logo.png';
import kruthusen_portrait_navbar from '../brand/kruthusen/navbar-portrait-background.webp';

import stiftelsenhemmet_logo from '../brand/stiftelsenhemmet/brand-logo.png';
import stiftelsenhemmet_navbar from '../brand/stiftelsenhemmet/navbar-background.webp';

import demo_logo from '../brand/demo/brand-logo.svg';
import demo_navbar from '../brand/demo/navbar-background.webp';
import demo_portrait_navbar from '../brand/demo/portrait-navbar-background.webp';

import useConfig from './useConfig';
import { useDeviceOrientation } from './useDeviceOrientation';

export const PrimaryColor = '#0D6936';

interface UseBrand {
  logo: ReactNode;
  navbarBackground: string;
  navbarColor: string;
}
export function useBrand(): UseBrand {
  const { organization_id } = useConfig();
  const { portrait } = useDeviceOrientation();

  switch (organization_id) {
    case 'wallenstam':
      return {
        logo: (
          <img
            src={wallenstam_logo}
            alt="Logo"
            className="h-full w-full bg-white object-contain px-4"
          />
        ),
        navbarBackground: `url(${wallenstam_navbar}) rgba(166, 187, 200, 0.6)`,
        navbarColor: 'black',
      };
    case 'brfgrindstugan':
      return {
        logo: (
          <img
            src={brfgrindstugan_logo}
            alt="Logo"
            className="h-full w-full object-contain"
          />
        ),
        navbarBackground: '#141E29',
        navbarColor: '#FFFFFF',
      };
    case 'brfdagsmejan':
      return {
        logo: (
          <img
            src={brfdagsmejan_logo}
            alt="Logo"
            className="h-full w-full object-contain"
          />
        ),
        navbarBackground: portrait
          ? `url(${demo_portrait_navbar}) rgba(166, 187, 200, 0.6)`
          : `url(${demo_navbar}) rgba(166, 187, 200, 0.6)`,
        navbarColor: '#FFFFFF',
      };
    case 'newsec':
      return {
        logo: (
          <img
            src={newsec_logo}
            alt="Logo"
            className="h-full w-full object-contain"
          />
        ),
        navbarBackground: '#333333',
        navbarColor: '#FFFFFF',
      };
    case 'genova':
      return {
        logo: (
          <img
            src={genova_logo}
            alt="Logo"
            className="h-full w-full bg-[#F9F9F9] object-contain px-4"
          />
        ),
        navbarBackground: `url(${genova_navbar}) rgba(20, 15, 15, 0.6)`,
        navbarColor: '#FFFFFF',
      };
    case 'stiftelsenhemmet':
      return {
        logo: (
          <img
            src={stiftelsenhemmet_logo}
            alt="Logo"
            className="h-full w-full bg-white object-contain px-4"
          />
        ),
        navbarBackground: `url(${stiftelsenhemmet_navbar}) rgba(20, 15, 15, 0.6)`,
        navbarColor: '#FFFFFF',
      };
    case 'cbre':
      return {
        logo: (
          <img
            src={cbre_logo}
            alt="Logo"
            className="h-full w-full bg-white object-contain px-4"
          />
        ),
        navbarBackground: `url(${cbre_navbar}) rgba(166, 187, 200, 0.6)`,
        navbarColor: '#FFFFFF',
      };
    case 'resona':
      return {
        logo: (
          <img
            src={resona_logo}
            alt="Logo"
            className="h-full w-full bg-white object-contain px-4"
          />
        ),
        navbarBackground: `url(${resona_navbar}) rgba(166, 187, 200, 0.6)`,
        navbarColor: '#FFFFFF',
      };
    case 'trosso':
      return {
        logo: (
          <img
            src={trosso_logo}
            alt="Logo"
            className="h-full w-full bg-white object-contain px-4"
          />
        ),
        navbarBackground: '#141E29',
        navbarColor: '#FFFFFF',
      };
    case 'kruthusen':
      return {
        logo: (
          <img
            src={kruthusen_logo}
            alt="Logo"
            className="h-full w-full bg-white object-contain p-[8px]"
          />
        ),
        navbarBackground: portrait
          ? `url(${kruthusen_portrait_navbar}) rgba(166, 187, 200, 0.6)`
          : '#283b50',
        navbarColor: '#FFFFFF',
      };
    case 'demo':
      return {
        logo: (
          <img
            src={demo_logo}
            alt="Logo"
            className="h-full w-full bg-white object-contain px-4"
          />
        ),
        navbarBackground: portrait
          ? `url(${demo_portrait_navbar}) rgba(166, 187, 200, 0.6)`
          : `url(${demo_navbar}) rgba(166, 187, 200, 0.6)`,
        navbarColor: '#FFFFFF',
      };
    default:
      return {
        logo: (
          <img
            src={allbinary_logo}
            alt="Logo"
            className="h-full w-full bg-white object-contain px-4"
          />
        ),
        navbarBackground: `url(${resona_navbar}) rgba(166, 187, 200, 0.6)`,
        navbarColor: '#FFFFFF',
      };
  }
}
