import { ApiFeatureCollection } from '@allbin/viu-api-client';

import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { useApiClient } from './useApiClient';
import { useIdentity } from '../providers/IdentityProvider';

const ONE_DAY = 24 * 60 * 60 * 1000;

export const useFeatures = (): UseQueryResult<ApiFeatureCollection> => {
  const apiClient = useApiClient();
  const { id } = useIdentity();
  const query = useQuery({
    queryKey: ['geojson', id],
    queryFn: async ({ queryKey: [, id] }) =>
      await apiClient.public.devices.getFeatures(id ?? ''),
    enabled: true,
    refetchInterval: ONE_DAY,
  });

  return query;
};
