import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { useApiClient } from './useApiClient';
import { ApiPublicConnector } from '@allbin/viu-api-client';
import useConfig from './useConfig';

export const useConnectors = (): UseQueryResult<ApiPublicConnector[]> => {
  const apiClient = useApiClient();
  const { organization_id } = useConfig();

  return useQuery({
    queryKey: ['connectors', organization_id],
    queryFn: () =>
      apiClient.public.organizations.getConnectors(organization_id),
  });
};
