import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { DepartureWithStop } from '../../types/publicTransits';

interface Props {
  departure: DepartureWithStop;
}
export const DepartureItem: FC<Props> = ({ departure }) => {
  return (
    <>
      <div>
        <p className="line-clamp-1 grow break-all">{departure.direction}</p>
        <p className="line-clamp-1 grow break-all opacity-70">
          {departure.stop}
        </p>
      </div>
      <div className="flex flex-col">
        <span className="w-max rounded-full bg-green px-2 text-white">
          {departure.lineName.substring(0, 3)}
        </span>
        <span className="flex items-center">
          {Math.round(departure.departsIn.as('minutes'))}
          <span className="ml-1 opacity-70 font-small">
            <FormattedMessage
              defaultMessage="min"
              description="Short for minutes"
            />
          </span>
        </span>
      </div>
    </>
  );
};
