import { DateTime } from 'luxon';
import { Weather, WeatherSymbol } from './weather';

export const smhiEndpoint =
  'https://opendata-download-metfcst.smhi.se/api/category/pmp3g/version/2/geotype/point/lon/{lon}/lat/{lat}/data.json';

export interface WeatherForecast {
  approvedTime: string;
  referenceTime: string;
  geometry: Geometry;
  timeSeries: TimeSeries[];
}

export interface Geometry {
  type: string;
  coordinates: Array<number[]>;
}

export interface TimeSeries {
  validTime: string;
  parameters: Parameter[];
}

export interface Parameter {
  name: Name;
  levelType: LevelType;
  level: number;
  unit: Unit;
  values: number[];
}

export enum LevelType {
  Hl = 'hl',
  Hmsl = 'hmsl',
}

export enum Name {
  Gust = 'gust',
  HccMean = 'hcc_mean',
  LccMean = 'lcc_mean',
  MccMean = 'mcc_mean',
  Msl = 'msl',
  Pcat = 'pcat',
  Pmax = 'pmax',
  Pmean = 'pmean',
  Pmedian = 'pmedian',
  Pmin = 'pmin',
  R = 'r',
  Spp = 'spp',
  T = 't',
  TccMean = 'tcc_mean',
  Tstm = 'tstm',
  Vis = 'vis',
  Wd = 'wd',
  Ws = 'ws',
  Wsymb2 = 'Wsymb2',
}

export enum Unit {
  Category = 'category',
  Cel = 'Cel',
  Degree = 'degree',
  HPa = 'hPa',
  KM = 'km',
  KgM2H = 'kg/m2/h',
  MS = 'm/s',
  Octas = 'octas',
  Percent = 'percent',
}

export const parseWeatherSymbol = (value: number): WeatherSymbol => {
  switch (value) {
    case 1:
      return 'Clear sky';
    case 2:
      return 'Nearly clear sky';
    case 3:
      return 'Variable cloudiness';
    case 4:
      return 'Halfclear sky';
    case 5:
      return 'Cloudy sky';
    case 6:
      return 'Overcast';
    case 7:
      return 'Fog';
    case 8:
      return 'Light rain showers';
    case 9:
      return 'Moderate rain showers';
    case 10:
      return 'Heavy rain showers';
    case 11:
      return 'Thunderstorm';
    case 12:
      return 'Light sleet showers';
    case 13:
      return 'Moderate sleet showers';
    case 14:
      return 'Heavy sleet showers';
    case 15:
      return 'Light snow showers';
    case 16:
      return 'Moderate snow showers';
    case 17:
      return 'Heavy snow showers';
    case 18:
      return 'Light rain';
    case 19:
      return 'Moderate rain';
    case 20:
      return 'Heavy rain';
    case 21:
      return 'Thunder';
    case 22:
      return 'Light sleet';
    case 23:
      return 'Moderate sleet';
    case 24:
      return 'Heavy sleet';
    case 25:
      return 'Light snowfall';
    case 26:
      return 'Moderate snowfall';
    case 27:
      return 'Heavy snowfall';
    default:
      return 'Unknown';
  }
};

export const timeSeriesToWeather = (timeSeries: TimeSeries): Weather => {
  const params = {
    symbol: timeSeries.parameters.find((p) => p.name === 'Wsymb2'),
    temperature: timeSeries.parameters.find((p) => p.name === 't'),
    windSpeed: timeSeries.parameters.find((p) => p.name === 'ws'),
    windDirection: timeSeries.parameters.find((p) => p.name === 'wd'),
  };

  if (
    !params.symbol ||
    !params.temperature ||
    !params.windSpeed ||
    !params.windDirection
  ) {
    throw new Error('missing parameter data, unable to read weather data');
  }

  return {
    time: DateTime.fromISO(timeSeries.validTime),
    symbol: parseWeatherSymbol(params.symbol.values[0]),
    temperature: Math.round(params.temperature.values[0]),
    windSpeed: Math.round(params.windSpeed.values[0]),
    windDirection: params.windDirection.values[0],
  };
};
