import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { NewsArticle, WIPArticle } from '../types/news';
import axios from 'axios';
import { useIdentity } from '../providers/IdentityProvider';
import { useEnv } from './useEnv';

const ONE_DAY = 24 * 60 * 60 * 1000;

interface WIPResult {
  items: WIPArticle[];
  top: WIPArticle[];
}

export const useNews = (): UseQueryResult<NewsArticle[]> => {
  const { api } = useEnv();
  const { apiKey } = useIdentity();

  return useQuery({
    queryKey: ['newslist', api, apiKey],
    queryFn: () =>
      axios
        .get<WIPResult>(`${api ?? ''}/public/temp/wip/kruthusen/news`, {
          headers: {
            'x-api-key': apiKey ?? '',
          },
        })
        .then((res) => convertType(res.data.items)),
    refetchInterval: ONE_DAY,
  });
};

const convertType = (news: WIPArticle[]): NewsArticle[] => {
  return news.map(
    (article) =>
      ({
        id: article.id,
        published: article.date,
        title: article.title,
        text: article.text,
        image: article.image
          ? `https://dynapp.wip.se/dynapp-server/public/kruthusen/kruthusen/web-public/attachment/view/news/${article.id}/${article.image}`
          : '',
      }) as NewsArticle,
  );
};
