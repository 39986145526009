import { useCallback, useEffect, useState } from 'react';

type Orientation = 'landscape' | 'portrait';

const getOrientation = (): DeviceOrientation => {
  const orientation =
    window.innerHeight - window.innerWidth > 0 ? 'portrait' : 'landscape';
  return {
    orientation,
    landscape: orientation === 'landscape',
    portrait: orientation === 'portrait',
  };
};

interface DeviceOrientation {
  orientation: Orientation;
  portrait: boolean;
  landscape: boolean;
}

export const useDeviceOrientation = (): DeviceOrientation => {
  const [deviceOrientation, setDeviceOrientation] =
    useState<DeviceOrientation>(getOrientation());

  const handleResize = useCallback(
    () => setDeviceOrientation(getOrientation()),
    [],
  );

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return deviceOrientation;
};
