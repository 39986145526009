import * as React from 'react';
import { Weather, WeatherIcon } from '../../hooks/weather/weather';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  weather: Weather;
};

export const NavPanelWeatherWidget: React.FC<Props> = ({ weather }) => {
  const navigate = useNavigate();
  const handleClick = useCallback(() => {
    navigate('/weather');
  }, [navigate]);

  return (
    <button className="flex flex-col items-center gap-2" onClick={handleClick}>
      <WeatherIcon
        symbol={weather.symbol}
        className="h-[48px] w-[48px] text-white"
      />
      <p className="body1">{weather.temperature}&deg;</p>
    </button>
  );
};
