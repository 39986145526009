import { ApiLocation } from '@allbin/viu-api-client';
import { Location, reviveLocation } from '../../types/location';

export const ddApiLocation: ApiLocation = {
  id: '91a43e4b-6b49-4bbf-a9a3-6d343d600402',
  city: 'Stockholm',
  coordinate: {
    crs: 'WGS84',
    x: 59.329323,
    y: 18.068581,
  },
  meta: {
    created_at: '2023-02-01T12:00:00.000Z',
    updated_at: '2023-02-01T12:00:00.000Z',
    created_by: 'admin',
  },
  organization_id: 'allbinary',
  street: 'Kungsgatan 1',
  zipcode: '111 11',
  site_name: 'Mitt Stockholmsområde',
  config: {},
};

export const ddLocation: Location = reviveLocation(ddApiLocation);
