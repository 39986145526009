import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as Sentry from '@sentry/react';
import { useEnv } from './hooks/useEnv';
import CoveringLoadingScreen from './components/CoveringLoadingScreen';
import { useVersion } from './hooks/useVersion';

export const DependencyGate: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const env = useEnv();
  const version = useVersion();

  if (
    version.isLoading ||
    version.isPending ||
    env.isLoading ||
    env.isPending
  ) {
    return <CoveringLoadingScreen message="Startar..." progressBar />;
  }

  if ((version.error && !version.isFetched) || (env.error && !env.isFetched)) {
    let eventId = '';

    if (env.error) {
      eventId = Sentry.captureMessage('Failed to load env.json', {
        extra: {
          error: env.error,
        },
      });
    }

    if (version.error) {
      eventId = Sentry.captureMessage('Failed to load version.json', {
        extra: {
          error: version.error,
        },
      });
    }

    return (
      <CoveringLoadingScreen
        message={
          <div className="flex flex-col gap-4 text-gray-900">
            <h2>
              <FormattedMessage defaultMessage="Ett fel uppstod vid uppstart, vänligen kontakta supporten" />
            </h2>
            <p className="text-center text-gray font-small">{eventId}</p>
          </div>
        }
        action={
          <button
            className="duration-50 flex flex-row rounded-md border-2 border-green-dark bg-green p-2 text-green-extraLight transition-colors ease-in-out active:border-green-dark active:bg-green-dark"
            onClick={() => window.location.reload()}
          >
            Försök igen
          </button>
        }
      />
    );
  }

  return <>{children}</>;
};
