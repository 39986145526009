import React, { FC, useMemo } from 'react';
import { Dialog } from '@headlessui/react';
import { Service } from '../types/service';
import { FormattedMessage } from 'react-intl';
import { useBookingResourcesWithEvents } from '../hooks/useBookingResourcesWithEvents';
import {
  ResourceDetails,
  ResourceWithEventsAndCapacity,
} from './ResourceDetails';
import { EventsTimeline } from './EventsTimeline';
// import { RenderQrCode } from './RenderQrCode';

interface Props {
  service: Service;
}
export const ServiceDetails: FC<Props> = ({ service }) => {
  const { data: resources_by_service_id } = useBookingResourcesWithEvents();

  const { name, photo, equipment, description } = service;

  const serviceResources = useMemo((): ResourceWithEventsAndCapacity[] => {
    if (!resources_by_service_id) {
      return [];
    }

    const resourcesWithEvents = resources_by_service_id[service.id] || [];

    return resourcesWithEvents.map((resource) => {
      const serviceResource = service.resources.find((r) => {
        if (!r.booking_ref) {
          return false;
        }

        return (
          r.booking_ref.connector_id === resource.connector_id &&
          r.booking_ref.source_id === resource.resource_source_id
        );
      });
      return { ...resource, capacity: serviceResource?.capacity };
    });
  }, [resources_by_service_id, service.id, service.resources]);

  return (
    <div className="flex w-full flex-col gap-8">
      <div className="flex w-full flex-col gap-4">
        {photo ? (
          <div className="flex h-[24rem] min-h-[24rem] w-full rounded">
            <img
              src={photo}
              alt="service"
              className="size-full bg-gray-800 object-cover"
            />
          </div>
        ) : null}
        <div className="flex w-full">
          <div className="flex flex-col gap-3">
            <div className="flex justify-between">
              <Dialog.Title as="h3" className="text-3xl font-medium text-white">
                <span>{name}</span>
              </Dialog.Title>
            </div>
            <div className="flex size-full justify-between gap-6 text-gray-200">
              <div className="flex h-full flex-col gap-6">
                {equipment.length > 0 ? (
                  <div className="flex flex-col gap-2">
                    <p className="text-lg font-bold">
                      <FormattedMessage defaultMessage="Utrustning" />
                    </p>
                    {equipment.map((e) => (
                      <p key={e}>{e}</p>
                    ))}
                  </div>
                ) : null}
                {description ? (
                  <div>
                    <p className="text-lg font-bold">
                      <FormattedMessage defaultMessage="Beskrivning" />
                    </p>
                    <p className="whitespace-pre-wrap">{description}</p>
                  </div>
                ) : null}
                {/* {serviceResources.length === 1 && serviceResources[0].url ? (
                  <RenderQrCode
                    url={serviceResources[0].url}
                    scale={7}
                    className="-ml-6"
                  />
                ) : null} */}
              </div>
            </div>
          </div>
          {serviceResources.length === 1 && service.resources[0].booking_ref ? (
            <div className="flex flex-col ml-auto h-[24rem] min-h-[24rem] text-green-200">
              <p className="mb-3 text-lg">
                <FormattedMessage defaultMessage="Bokningar" />
              </p>
              <EventsTimeline events={serviceResources[0].events} />
            </div>
          ) : null}
        </div>
      </div>
      {serviceResources.length > 1 ? (
        <div className="flex w-full justify-between flex-wrap text-green-200">
          {serviceResources.map((resource) => {
            return (
              <ResourceDetails
                key={resource.resource_source_id}
                resource={resource}
                className={serviceResources.length === 1 ? 'w-full' : ''}
                hideName={serviceResources.length === 1}
              />
            );
          })}
        </div>
      ) : null}
    </div>
  );
};
