import React from 'react';

interface Props {
  className?: string;
}

export const IconSnowflake: React.FC<Props> = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_404_19009)">
        <path
          d="M10 4L12 5L14 4M12 2V8.5L15 10.22"
          stroke="#85A9D6"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.9282 6.26794L18.0622 8.5L19.9282 9.73205M20.6603 7L15.0311 10.25L15.0415 13.7081"
          stroke="#85A9D6"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.9282 14.2679L18.0622 15.5L17.9282 17.732M20.6603 17L15.0311 13.75L12.0415 15.4881"
          stroke="#85A9D6"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14 20L12 19L10 20M12 22V15.5L9 13.78"
          stroke="#85A9D6"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.07177 17.7321L5.9378 15.5L4.07177 14.268M3.33972 17L8.96889 13.75L8.95845 10.2919"
          stroke="#85A9D6"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.07177 9.73206L5.9378 8.5L6.07177 6.26795M3.33972 7L8.96889 10.25L11.9585 8.51193"
          stroke="#85A9D6"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_404_19009">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconSnowflake;
