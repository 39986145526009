import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import DepartureRow from './DepartureRow';
import { Departure } from '../../types/publicTransits';
import { cn } from '../../utils/classNames';

interface Props {
  label: string;
  departures: Departure[];
  backgroundIsDark?: boolean;
}

const StopDeparturesList: FC<Props> = ({
  label,
  departures,
  backgroundIsDark,
}) => {
  return (
    <div
      className={cn(
        'flex w-[600px] max-w-full flex-col',
        backgroundIsDark ? 'text-white' : 'text-gray-900',
      )}
    >
      <div className="flex items-center h-20">
        <h2 className="mb-5 break-words line-clamp-2 pb-1">{label}</h2>
      </div>
      {departures.length === 0 ? (
        <p className="text-center">
          <FormattedMessage defaultMessage="Inga avgångar" />
        </p>
      ) : (
        <div className="grid grid-cols-[min-content_1fr_min-content] gap-x-4 gap-y-6">
          <span className="caption">
            <FormattedMessage defaultMessage="Linje" />
          </span>
          <span className="caption">
            <FormattedMessage defaultMessage="Destination" />
          </span>
          <span className="text-right caption">
            <FormattedMessage defaultMessage="Avgår" />
          </span>
          {departures.map((dep, i) => (
            <DepartureRow
              key={`departure-${i}`}
              departure={dep}
              backgroundIsDark={backgroundIsDark}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default StopDeparturesList;
