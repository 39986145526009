import { Announcement, reviveAnnouncement } from '../types/announcements';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useApiClient } from './useApiClient';
import { useIdentity } from '../providers/IdentityProvider';

export const useAnnouncements = (): UseQueryResult<Announcement[]> => {
  const apiClient = useApiClient();
  const { id } = useIdentity();

  return useQuery({
    queryKey: ['devices', id, 'announcements'],
    queryFn: ({ queryKey: [, id] }) =>
      apiClient.public.devices
        .getAnnouncements(id ?? '')
        .then((announcements) =>
          announcements.map((announcement) => reviveAnnouncement(announcement)),
        ),
    enabled: !!id,
    refetchInterval: 10 * 60 * 1000,
  });
};
