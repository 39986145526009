import React, { FC, ReactNode } from 'react';
import { Marker } from 'react-map-gl';

interface Props {
  label: string;
  icon: ReactNode;
  latitude: number;
  longitude: number;
  onClick?: () => void;
  hideText?: boolean;
}

export const MapMarker: FC<Props> = ({
  label,
  icon,
  latitude,
  longitude,
  onClick,
  hideText,
}) => {
  return (
    <Marker
      key={label}
      latitude={latitude}
      longitude={longitude}
      onClick={onClick}
    >
      <div className="flex w-full flex-col items-center gap-1">
        <div className="h-8 w-8 rounded-full bg-white p-1">{icon}</div>
        {!hideText ? (
          <div className="flex rounded-md bg-gray-800 px-1 text-sm text-white">
            {label}
          </div>
        ) : null}
      </div>
    </Marker>
  );
};
