import { Service, reviveService } from '../types/service';

import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { useApiClient } from './useApiClient';
import { useIdentity } from '../providers/IdentityProvider';

export const useServices = (): UseQueryResult<Service[]> => {
  const apiClient = useApiClient();
  const { id } = useIdentity();
  const query = useQuery({
    queryKey: ['services', id],
    queryFn: async ({ queryKey: [, id] }) =>
      await apiClient.public.devices
        .getServices(id ?? '')
        .then((services) => services.map((service) => reviveService(service))),
    enabled: true,
  });

  return query;
};
