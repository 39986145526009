import React, { FC } from 'react';
import { useBrand } from '../../hooks/useBrand';
import Footer from '../NavPanel/Footer';
import { BigButtons } from './BigButtons';
import DepartsSoonList from '../PublicTransit/DepartsSoonList';

const PortraitBottomSection: FC = () => {
  const { navbarBackground, navbarColor } = useBrand();

  return (
    <div
      className="relative flex h-[510px] min-h-[510px] w-full flex-col justify-center !bg-cover bg-center bg-no-repeat shadow-md"
      style={{
        color: navbarColor,
        background: navbarBackground || undefined,
      }}
    >
      <div className="absolute inset-0 z-0 bg-black/60" />
      <div className="flex w-full grow justify-center">
        <BigButtons />
      </div>
      <div className="relative min-h-[80px] w-full backdrop-blur-sm">
        <div className="absolute -top-5 z-20">
          <Footer />
        </div>
        <DepartsSoonList horizontal />
      </div>
    </div>
  );
};

export default PortraitBottomSection;
