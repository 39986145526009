import { DateTime } from 'luxon';
import IconSun from '../../icons/weather/IconSun';
import React, { useMemo } from 'react';
import IconPartlyCloudy from '../../icons/weather/IconPartlyCloudy';
import IconMist from '../../icons/weather/IconMist';
import IconRain from '../../icons/weather/IconRain';
import IconStorm from '../../icons/weather/IconStorm';
import IconSnowflake from '../../icons/weather/IconSnowflake';
import IconCloud from '../../icons/weather/IconCloud';

export interface Weather {
  time: DateTime;
  symbol: WeatherSymbol;
  temperature: number;
  windSpeed: number;
  windDirection: number;
}

export type WeatherSymbol =
  | 'Unknown'
  | 'Clear sky'
  | 'Nearly clear sky'
  | 'Variable cloudiness'
  | 'Halfclear sky'
  | 'Cloudy sky'
  | 'Overcast'
  | 'Fog'
  | 'Light rain showers'
  | 'Moderate rain showers'
  | 'Heavy rain showers'
  | 'Thunderstorm'
  | 'Light sleet showers'
  | 'Moderate sleet showers'
  | 'Heavy sleet showers'
  | 'Light snow showers'
  | 'Moderate snow showers'
  | 'Heavy snow showers'
  | 'Light rain'
  | 'Moderate rain'
  | 'Heavy rain'
  | 'Thunder'
  | 'Light sleet'
  | 'Moderate sleet'
  | 'Heavy sleet'
  | 'Light snowfall'
  | 'Moderate snowfall'
  | 'Heavy snowfall';

export const WeatherIcon: React.FC<{
  symbol: WeatherSymbol;
  className?: string;
}> = ({ symbol, className }) => {
  const iconClassName = useMemo(
    () => `w-12 h-12 ${className || ''}`,
    [className],
  );
  switch (symbol) {
    case 'Clear sky':
    case 'Nearly clear sky':
      return <IconSun className={iconClassName} />;
    case 'Variable cloudiness':
    case 'Halfclear sky':
      return <IconPartlyCloudy className={iconClassName} />;
    case 'Cloudy sky':
    case 'Overcast':
      return <IconCloud className={iconClassName} />;
    case 'Fog':
      return <IconMist className={iconClassName} />;
    case 'Light rain showers':
    case 'Moderate rain showers':
    case 'Heavy rain showers':
    case 'Light rain':
    case 'Moderate rain':
    case 'Heavy rain':
      return <IconRain className={iconClassName} />;
    case 'Thunderstorm':
    case 'Thunder':
      return <IconStorm className={iconClassName} />;
    case 'Light snow showers':
    case 'Moderate snow showers':
    case 'Heavy snow showers':
    case 'Light sleet':
    case 'Moderate sleet':
    case 'Heavy sleet':
    case 'Light snowfall':
    case 'Moderate snowfall':
    case 'Heavy snowfall':
    case 'Light sleet showers':
    case 'Moderate sleet showers':
    case 'Heavy sleet showers':
      return <IconSnowflake className={iconClassName} />;
    default:
      console.error(`missing symbol icon: ${symbol}`);
      return <div className={`rounded-full bg-gray-light ${iconClassName}`} />;
  }
};
