import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';
import AttachmentsView from './views/AttachmentsView';
import InstallView from './views/InstallView';
import PublicTransitView from './views/PublicTransitView';
import TenantRegistryView from './views/TenantRegistryView';
import NavPanel from './components/NavPanel/NavPanel';
import CoveringLoadingScreen from './components/CoveringLoadingScreen';
import AlertComponent from './components/AlertComponent';
import ErrorBar from './components/ErrorBar';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useIsOffline } from './hooks/useIsOffline';
import { useDeviceOrientation } from './hooks/useDeviceOrientation';
import * as Sentry from '@sentry/react';
import WeatherView from './views/WeatherView';
import { useIdentity } from './providers/IdentityProvider';
import { IconBuilding, IconCutlery, IconLeaf } from '@allbin/icons';
import { useDevice } from './hooks/useDevice';
import { useLocationInternal } from './hooks/useLocation';
import { useAnnouncements } from './hooks/useAnnouncements';
import { useEmbeddedUrls } from './hooks/useEmbeddedUrls';
import { useAttachments } from './hooks/useAttachments';
import { useUnits } from './hooks/useUnits';
import { useFloors } from './hooks/useFloors';
import VerticalTopSection from './components/PortraitTopSection/PortraitTopSection';
import PortraitBottomSection from './components/PortraitBottomSection/PortraitBottomSection';
import FloorPlanView from './views/FloorPlanView';
import ServicesView from './views/ServicesView';
import { useBookingResourcesWithEvents } from './hooks/useBookingResourcesWithEvents';
import AttachmentCache from './components/Attachments/AttachmentCache';
import EmbeddedUrlView from './views/EmbeddedUrlView';
import { useNavigateToDefaultView } from './hooks/useNavigateToDefaultView';

const errorMessages = defineMessages({
  offline: { defaultMessage: 'Inget internet' },
  communicationError: { defaultMessage: 'Kan inte uppdatera hyresregister' },
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const App: FC = () => {
  const intl = useIntl();
  const identity = useIdentity(useCallback((state) => state, []));
  const navigateToDefaultView = useNavigateToDefaultView();

  const device = useDevice();
  const location = useLocationInternal();
  const announcements = useAnnouncements();
  const units = useUnits();
  const floors = useFloors();
  const embeddedUrls = useEmbeddedUrls();
  const attachments = useAttachments();
  useBookingResourcesWithEvents();

  const offline = useIsOffline();
  const { portrait } = useDeviceOrientation();

  useEffect(() => {
    if (!identity.id) {
      Sentry.setUser(null);
      return;
    }

    Sentry.setUser({
      ...identity,
      ip_address: '{{auto}}',
    });
  }, [identity]);

  const error = useMemo(() => {
    if (offline) {
      return intl.formatMessage(errorMessages.offline);
    }
    if (
      announcements.isError ||
      attachments.isError ||
      device.isError ||
      embeddedUrls.isError ||
      floors.isError ||
      location.isError ||
      units.isError
    ) {
      return intl.formatMessage(errorMessages.communicationError);
    }
    return undefined;
  }, [
    announcements.isError,
    attachments.isError,
    device.isError,
    embeddedUrls.isError,
    floors.isError,
    intl,
    location.isError,
    units.isError,
    offline,
  ]);

  const isLoading = useMemo(
    () =>
      announcements.isLoading ||
      attachments.isLoading ||
      device.isLoading ||
      embeddedUrls.isLoading ||
      floors.isLoading ||
      location.isLoading ||
      units.isLoading,
    [
      announcements.isLoading,
      attachments.isLoading,
      device.isLoading,
      embeddedUrls.isLoading,
      floors.isLoading,
      location.isLoading,
      units.isLoading,
    ],
  );

  useEffect(() => {
    if (!isLoading) {
      navigateToDefaultView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const loadingMessage = useMemo(() => {
    if (!device) {
      return intl.formatMessage({
        defaultMessage: 'Söker efter konfiguration',
      });
    }

    if (isLoading) {
      return intl.formatMessage({ defaultMessage: 'Laddar hyresregister' });
    }
    return undefined;
  }, [device, intl, isLoading]);

  if (isLoading || !device || loadingMessage) {
    return (
      <CoveringLoadingScreen message={loadingMessage} progressBar={!!device} />
    );
  }

  if (device.data?.state !== 'installed') {
    return <InstallView />;
  }

  return (
    <div className="flex h-screen flex-col">
      <div
        className={`flex h-full grow overflow-auto ${
          portrait ? 'flex-col' : 'flex-row'
        }`}
      >
        {portrait ? <VerticalTopSection /> : <NavPanel />}
        <div className="flex h-full w-full overflow-auto">
          <div className="flex grow flex-col overflow-hidden">
            {announcements.data && (
              <AlertComponent announcements={announcements.data} />
            )}
            <SentryRoutes>
              <Route
                path="/tenants"
                element={<TenantRegistryView portrait={portrait} />}
              />
              <Route
                path="/publictransit"
                element={<PublicTransitView portrait={portrait} />}
              />
              <Route
                path="/energy-declaration-ovk"
                element={
                  <AttachmentsView
                    icon={<IconLeaf />}
                    title={
                      <FormattedMessage defaultMessage="Energideklaration / OVK" />
                    }
                    attachments={attachments.data?.['energy-declaration-ovk']}
                    portrait={portrait}
                  />
                }
              />
              <Route
                path="/property-info"
                element={
                  <AttachmentsView
                    icon={<IconBuilding />}
                    title={<FormattedMessage defaultMessage="Fastighetsinfo" />}
                    attachments={attachments.data?.['property-info']}
                    portrait={portrait}
                  />
                }
              />
              <Route
                path="/restaurant-menu"
                element={
                  <AttachmentsView
                    icon={<IconCutlery />}
                    title={
                      <FormattedMessage defaultMessage="Restaurangmenyer" />
                    }
                    attachments={attachments.data?.['restaurant-menu']}
                    portrait={portrait}
                  />
                }
              />
              <Route
                path="/documents"
                element={
                  <AttachmentsView
                    title={<FormattedMessage defaultMessage="Dokument" />}
                    attachments={attachments.data?.['other']}
                    portrait={portrait}
                  />
                }
              />
              <Route
                path="/weather"
                element={<WeatherView portrait={portrait} />}
              />
              <Route
                path="/embedded-urls/:embeddedUrlId"
                element={<EmbeddedUrlView portrait={portrait} />}
              />
              <Route
                path="/floor-plan"
                element={<FloorPlanView portrait={portrait} />}
              />
              <Route
                path="/services"
                element={<ServicesView portrait={portrait} />}
              />
            </SentryRoutes>
          </div>
        </div>
        {portrait ? <PortraitBottomSection /> : null}
      </div>
      <ErrorBar value={error} />
      <AttachmentCache />
    </div>
  );
};

export default App;
