import React, { FC, useCallback, useState } from 'react';
import { PageHeader } from '../components/PageHeader';
import { FormattedMessage } from 'react-intl';
import { IconHomeSimple, IconWhiteFlag } from '@allbin/icons';
import NeighborhoodMap from '../components/NeighborhoodMap/NeighborhoodMap';
import { useLocationInternal } from '../hooks/useLocation';
import { Marker } from 'react-map-gl';
import { useServices } from '../hooks/useServices';
import MapServiceMarker from '../components/NeighborhoodMap/MapServiceMarker';
import { ServiceModal } from '../components/Modals/ServiceModal';
import { Service } from '../types/service';
import Button from '../components/Button';
import { useConfig } from '../providers/ConfigProvider';
import { useNavigateToDefaultView } from '../hooks/useNavigateToDefaultView';
import { useInteractionTimeout } from '../hooks/useInteractionTimeout';

interface Props {
  portrait?: boolean;
}
const ServicesView: FC<Props> = ({ portrait }) => {
  const { data: location } = useLocationInternal();
  const { data: services } = useServices();

  const { interactionTimeout } = useConfig();
  const navigateToDefaultView = useNavigateToDefaultView();
  useInteractionTimeout(interactionTimeout, navigateToDefaultView);

  const [showMeetingRooms, setShowMeetingRooms] = useState(false);
  const [currentZoom, setCurrentZoom] = useState(17);

  const [selectedService, setSelectedService] = useState<Service | undefined>(
    undefined,
  );
  const [showServiceModal, setShowServiceModal] = useState(false);

  const onClick = useCallback((service: Service) => {
    setShowServiceModal(true);
    setSelectedService(service);
  }, []);

  return (
    <main className="flex h-full grow flex-col">
      <PageHeader
        icon={<IconWhiteFlag />}
        title={<FormattedMessage defaultMessage="Servicekarta" />}
        slim={portrait}
        content={
          <Button
            label={
              showMeetingRooms ? (
                <FormattedMessage defaultMessage="Dölj mötesrum" />
              ) : (
                <FormattedMessage defaultMessage="Visa mötesrum" />
              )
            }
            onClick={() => setShowMeetingRooms((value) => !value)}
            className="ml-auto"
          />
        }
      />
      <section className="flex grow overflow-hidden">
        <NeighborhoodMap
          zoom={17}
          onZoom={(newZoom) => setCurrentZoom(newZoom)}
        >
          {services
            ? services
                .filter((s) => {
                  if (s.type === 'conference_room') {
                    return showMeetingRooms;
                  }
                  return !showMeetingRooms;
                })
                .map((s) => (
                  <MapServiceMarker
                    key={s.id}
                    service={s}
                    onClick={() => onClick(s)}
                    hideText={currentZoom < 16.3}
                  />
                ))
            : null}
          {location?.coordinate && (
            <Marker
              latitude={location?.coordinate?.y}
              longitude={location?.coordinate?.x}
            >
              <IconHomeSimple className="h-9 w-9 fill-green-dark stroke-2" />
            </Marker>
          )}
        </NeighborhoodMap>
      </section>
      {showServiceModal ? (
        <ServiceModal
          show={true}
          service={selectedService}
          onClose={() => setShowServiceModal(false)}
        />
      ) : null}
    </main>
  );
};

export default ServicesView;
