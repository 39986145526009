import React, { FC, useMemo } from 'react';
import { Company } from '../../types/company';
import { Modal } from '../Modal';
import { useInteractionTimeout } from '../../hooks/useInteractionTimeout';
import { useConfig } from '../../providers/ConfigProvider';
import { CompanyTenant, Unit } from '../../types/unit';
import { CompanyDetails } from '../CompanyDetails';
import { ServiceDetails } from '../ServiceDetails';
import { useUnitsServices } from '../../hooks/useUnitsServices';

interface Props {
  unit?: Unit;
  show: boolean;
  onClose: () => void;
}

export const UnitModal: FC<Props> = ({ unit, show, onClose }) => {
  const { interactionTimeout } = useConfig();
  useInteractionTimeout(interactionTimeout - 2000, onClose);
  const unitsServices = useUnitsServices();

  const companies = useMemo(() => {
    if (!unit) {
      return [];
    }

    return (unit.tenants.filter((t) => t.type === 'company') as CompanyTenant[])
      .map((t) => t.company as Company)
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  }, [unit]);

  const hasContent = useMemo(() => {
    if (!unit) {
      return false;
    }

    return unitsServices[unit.id].length > 0 || companies.length > 0;
  }, [companies.length, unitsServices, unit]);

  if (!show || !unit) {
    return null;
  }

  return (
    <Modal open={true} onClose={onClose} className="w-4/5">
      <div className="flex w-full flex-col gap-24">
        {!hasContent ? <i>Ledig lokal</i> : null}
        {unitsServices[unit.id].map((service) => (
          <ServiceDetails key={service.id} service={service} />
        ))}
        {companies.map((company) => (
          <CompanyDetails key={company.name} company={company} />
        ))}
      </div>
    </Modal>
  );
};
