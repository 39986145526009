import '@fontsource/inter/variable.css';
import { StartReturnType } from 'msw/lib/types/setupWorker/glossary';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import { MemoryRouter, Route, Routes } from 'react-router-dom';

import App from './App';

import Gallery from './gallery/Gallery';
import './index.css';
import { worker } from './mocks/browser';
import Providers from './providers/Providers';
import { IdentityProvider } from './providers/IdentityProvider';
import LoginView from './views/LoginView';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { DependencyGate } from './DependencyGate';
import { useSentry } from './sentry';

async function prepare(): StartReturnType {
  if (
    import.meta.env.MODE === 'galleryNoMsw' ||
    import.meta.env.MODE === 'gallery'
  ) {
    document.title = 'TenantViu Gallery';
  }
  if (import.meta.env.MODE === 'msw' || import.meta.env.MODE === 'gallery') {
    document.title += ' (MSW)';
    localStorage.setItem('id', 'J183002467');
    localStorage.setItem('type', 'eloview');
    localStorage.setItem(
      'psk',
      '5b915aca218a5e06c5782e93f87fff01c2d7f6eea5e36f224d37645a6d126b1e',
    );
    return worker.start({
      onUnhandledRequest(req) {
        if (
          req.url.href.startsWith('http://localhost:3000') ||
          req.url.href.startsWith('http://localhost:3001')
        ) {
          //Requests to 3000 and 3001 are always ui development vite server itself, so always ignore.
          return;
        }
        console.warn('Unhandled: %s %s', req.method, req.url.href);
      },
    });
  }

  return Promise.resolve(undefined);
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      refetchInterval: 60 * 60 * 1000, // Refetch every hour by default
    },
  },
});

const WithSentry: React.FC<{ children: React.ReactNode }> = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  useSentry();
  return <>{children}</>;
};

prepare()
  .then(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    ReactDOM.createRoot(document.getElementById('root')!).render(
      <React.StrictMode>
        <WithSentry>
          <IntlProvider locale="sv-SE" defaultLocale="sv-SE">
            <QueryClientProvider client={queryClient}>
              <div className="h-screen bg-white">
                <DependencyGate>
                  <IdentityProvider>
                    <MemoryRouter>
                      <Routes>
                        <Route path="/login" element={<LoginView />} />
                        <Route
                          path="*"
                          element={
                            <Providers>
                              {import.meta.env.MODE === 'gallery' ||
                              import.meta.env.MODE === 'galleryNoMsw' ? (
                                <Gallery />
                              ) : (
                                <App />
                              )}
                            </Providers>
                          }
                        />
                      </Routes>
                    </MemoryRouter>
                  </IdentityProvider>
                </DependencyGate>
              </div>
            </QueryClientProvider>
          </IntlProvider>
        </WithSentry>
      </React.StrictMode>,
    );
  })
  .catch((err) => {
    throw err;
  });
