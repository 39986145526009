import { useEffect } from 'react';

export const useInteractionTimeout = (
  timeout: number,
  onTimeout: () => void,
  repeat?: boolean,
): void => {
  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    const callback = (): void => {
      onTimeout();

      if (repeat) {
        timer = setTimeout(callback, timeout);
      }
    };

    timer = setTimeout(callback, timeout);

    const handleInteractionTimer = (): void => {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(callback, timeout);
    };

    document.body.addEventListener('keydown', handleInteractionTimer, true);
    document.body.addEventListener('pointerdown', handleInteractionTimer, true);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
      document.body.removeEventListener(
        'keydown',
        handleInteractionTimer,
        true,
      );
      document.body.removeEventListener(
        'pointerdown',
        handleInteractionTimer,
        true,
      );
    };
  }, [timeout, onTimeout, repeat]);
};
