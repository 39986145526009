import { useLocation, useNavigate } from 'react-router-dom';
import { useConfig } from '../providers/ConfigProvider';
import { useCallback } from 'react';
import { useAttachments } from './useAttachments';
import { useEmbeddedUrls } from './useEmbeddedUrls';
import { ApiAttachmentCategory } from '@allbin/viu-types';

export const useNavigateToDefaultView = (
  refs?: React.MutableRefObject<HTMLDivElement | null>[],
): (() => void) => {
  const navigate = useNavigate();
  const config = useConfig();
  const location = useLocation();
  const { defaultStartView } = config;
  const { data: attachments } = useAttachments();
  const { data: embeddedUrls } = useEmbeddedUrls();

  const resetRefsScroll = useCallback(() => {
    if (refs && refs.length > 0) {
      refs.forEach((ref) => {
        ref.current?.scrollTo(0, 0);
      });
    }
  }, [refs]);

  const navigateToTenants = useCallback(() => {
    navigate('/tenants');
    resetRefsScroll();
  }, [navigate, resetRefsScroll]);

  const callback = useCallback(() => {
    const pathname = location.pathname.slice(1);
    if (pathname === defaultStartView) {
      resetRefsScroll();
      return;
    }

    const parts = defaultStartView.split('/');
    const category = parts[0];
    // Attachments
    if (
      [
        'energy-declaration-ovk',
        'property-info',
        'restaurant-menu',
        'documents',
      ].includes(category)
    ) {
      if (!attachments) {
        navigateToTenants();
        return;
      }
      const categoryAttachments =
        attachments[category as ApiAttachmentCategory];
      if (categoryAttachments.length === 0) {
        navigateToTenants();
        return;
      }
      if (parts.length === 1) {
        navigate(`/${category}`);
        return;
      }
      const id = parts[1];
      const attachmentExists = categoryAttachments.find((att) => att.id === id);
      if (attachmentExists) {
        navigate(`/${category}?attachment=${id}`);
      } else {
        navigateToTenants();
      }
    }
    // Embedded urls
    else if (category === 'embedded-urls') {
      if (parts.length === 1 || embeddedUrls?.length === 0) {
        navigateToTenants();
        return;
      }
      const id = parts[1];
      const urlExists = embeddedUrls?.find((url) => url.id === id);
      if (urlExists) {
        navigate(`/${category}/${id}`);
      } else {
        navigateToTenants();
      }
    } else {
      navigate(`/${category}`);
    }
  }, [
    attachments,
    defaultStartView,
    embeddedUrls,
    location.pathname,
    navigate,
    navigateToTenants,
    resetRefsScroll,
  ]);

  return callback;
};
