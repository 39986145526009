import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { BookableResourceWithEvents } from '../types/bookableResource';
import { useApiClient } from './useApiClient';
import { useCallback, useMemo } from 'react';
import { useServices } from './useServices';
import { useUnits } from './useUnits';
import { useConnectors } from './useConnectors';
import { ApiServiceResource } from '@allbin/viu-types';

interface EventsFetchInfo {
  name: string;
  service_id: string;
  connector_id: string;
  source_id: string;
}

export const useBookingResourcesWithEvents = (): UseQueryResult<
  Record<string, BookableResourceWithEvents[]>
> => {
  const apiClient = useApiClient();
  const { data: services } = useServices();
  const { data: units } = useUnits();
  const { data: connectors } = useConnectors();

  const eventsToFetch = useMemo((): EventsFetchInfo[] => {
    if (!services || !units) {
      return [];
    }

    return services.flatMap((s) =>
      s.resources
        .filter(
          (
            r,
          ): r is ApiServiceResource & {
            booking_ref: { connector_id: string; source_id: string };
          } => !!r.booking_ref,
        )
        .map((r) => ({ name: r.name, service_id: s.id, ...r.booking_ref })),
    );
  }, [services, units]);

  const fetchAllEvents = useCallback(async (): Promise<
    Record<string, BookableResourceWithEvents[]>
  > => {
    const result: BookableResourceWithEvents[] = eventsToFetch.map((e) => ({
      name: e.name,
      service_id: e.service_id,
      resource_source_id: e.source_id,
      connector_id: e.connector_id,
      events: [],
    }));

    if (!connectors) {
      return {};
    }

    await Promise.all(
      eventsToFetch.map(
        async ({ connector_id, source_id }, index): Promise<void> => {
          const events = await apiClient.public.connectors.getResourceBookings(
            connector_id,
            source_id,
          );

          result[index].events = events;
        },
      ),
    );

    return result.reduce<Record<string, BookableResourceWithEvents[]>>(
      (rbk, event) => {
        if (!rbk[event.service_id]) {
          rbk[event.service_id] = [];
        }
        rbk[event.service_id].push(event);
        return rbk;
      },
      {},
    );
  }, [apiClient.public.connectors, connectors, eventsToFetch]);

  return useQuery({
    queryKey: ['booking', 'resources', 'events', eventsToFetch],
    queryFn: fetchAllEvents,
    refetchInterval: 60 * 1000, // 1 minute
  });
};

export const isCurrentlyUsed = (
  resource: BookableResourceWithEvents,
): boolean => {
  const now = new Date();
  return resource.events.some(
    ({ from, to }) => now > new Date(from) && now < new Date(to),
  );
};
