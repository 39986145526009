import React from 'react';

interface Props {
  className?: string;
}

export const IconStorm: React.FC<Props> = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_404_19045)">
        <path
          d="M7.00002 18C5.75229 18 4.55567 17.5259 3.6734 16.682C2.79113 15.8381 2.29547 14.6935 2.29547 13.5C2.29547 12.3065 2.79113 11.1619 3.6734 10.318C4.55567 9.47412 5.75229 9.00001 7.00002 9.00001C7.2947 7.68719 8.15678 6.5335 9.3966 5.79273C10.0105 5.42594 10.6987 5.17156 11.4218 5.04412C12.1449 4.91669 12.8889 4.91869 13.6111 5.05001C14.3334 5.18133 15.0199 5.43941 15.6313 5.80949C16.2428 6.17958 16.7672 6.65443 17.1748 7.20694C17.5823 7.75945 17.865 8.37879 18.0066 9.02961C18.1482 9.68043 18.1459 10.35 18 11H19C19.9283 11 20.8185 11.3688 21.4749 12.0251C22.1313 12.6815 22.5 13.5718 22.5 14.5C22.5 15.4283 22.1313 16.3185 21.4749 16.9749C20.8185 17.6313 19.9283 18 19 18H18"
          fill="#E7E8E9"
        />
        <path
          d="M7.00002 18C5.75229 18 4.55567 17.5259 3.6734 16.682C2.79113 15.8381 2.29547 14.6935 2.29547 13.5C2.29547 12.3065 2.79113 11.1619 3.6734 10.318C4.55567 9.47412 5.75229 9.00001 7.00002 9.00001C7.2947 7.68719 8.15678 6.5335 9.3966 5.79273C10.0105 5.42594 10.6987 5.17156 11.4218 5.04412C12.1449 4.91669 12.8889 4.91869 13.6111 5.05001C14.3334 5.18133 15.0199 5.43941 15.6313 5.80949C16.2428 6.17958 16.7672 6.65443 17.1748 7.20694C17.5823 7.75945 17.865 8.37879 18.0066 9.02961C18.1482 9.68043 18.1459 10.35 18 11H19C19.9283 11 20.8185 11.3688 21.4749 12.0251C22.1313 12.6815 22.5 13.5718 22.5 14.5C22.5 15.4283 22.1313 16.3185 21.4749 16.9749C20.8185 17.6313 19.9283 18 19 18H18H7.00002Z"
          stroke="#E7E8E9"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13 14L11 18H14L12 22"
          stroke="#FFD96E"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_404_19045">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconStorm;
