import { ApiDevice } from '@allbin/viu-api-client';
import { DateTime } from 'luxon';
import { reviveDevice } from '../../types/device';

export const ddApiDevice: ApiDevice = {
  id: '7440db15-72c0-4a6f-8041-be92e6bc5d3c',
  name: 'Mock device',
  meta: {
    created_at: DateTime.now().minus({ months: 1 }).toISO() ?? '',
    created_by: 'Mock user',
    updated_at: DateTime.now().minus({ months: 1 }).toISO() ?? '',
  },
  organization_id: 'allbinary',
  type: 'eloview',
  state: 'installed',
  location_id: '91a43e4b-6b49-4bbf-a9a3-6d343d600402',
  status: {
    hardware_online: true,
    software_online: true,
    last_seen: DateTime.now().minus({ minutes: 30 }).toISO() ?? '',
  },
  source_id: 'mockDevice-sourceId1',
  placement: 'Trapphuset',
  hardware_id: `J183002467`,
  config: {},
};

export const ddDevice = reviveDevice(ddApiDevice);
