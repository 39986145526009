import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios from 'axios';
import { smhiEndpoint, timeSeriesToWeather, WeatherForecast } from './smhi';
import { Weather } from './weather';
import { DateTime } from 'luxon';
import { useLocationInternal } from '../useLocation';

export const useWeather = (): UseQueryResult<Weather[]> => {
  const { data: location } = useLocationInternal();
  const url = useMemo<string>(() => {
    if (!location?.coordinate) {
      return '';
    }
    return smhiEndpoint
      .replace('{lon}', location.coordinate.x.toFixed(6))
      .replace('{lat}', location.coordinate.y.toFixed(6));
  }, [location?.coordinate]);

  return useQuery({
    queryKey: ['weather', 'forecast', url],
    queryFn: ({ queryKey: [, , url] }) =>
      axios
        .get<WeatherForecast>(url)
        .then((resp) =>
          resp.data.timeSeries.map((ts) => timeSeriesToWeather(ts)),
        ),
    enabled: !!url,
    retry: false,
    refetchOnMount: false,
    refetchInterval: 60 * 60 * 1000,
  });
};

export const useCurrentWeather = (): Weather | undefined => {
  const [time, setTime] = useState(DateTime.now());

  const tick = useCallback(() => {
    const nextTime = DateTime.now();
    setTime((prev) =>
      prev.toFormat('HH:mm').localeCompare(nextTime.toFormat('HH:mm')) === 0
        ? prev
        : nextTime,
    );
  }, []);

  useEffect(() => {
    const interval = setInterval(tick, 60 * 1000);
    return () => clearInterval(interval);
  }, [tick]);

  const { data: forecast } = useWeather();

  return useMemo<Weather | undefined>(
    () =>
      forecast?.reduce<Weather | undefined>((closest, weather) => {
        if (!closest || weather.time.diff(time) <= closest.time.diff(time)) {
          return weather;
        }

        return closest;
      }, undefined),
    [forecast, time],
  );
};
