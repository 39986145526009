import { DateTime, Duration } from 'luxon';

export const reviveDeparture = (dep: ApiDeparture): Departure => ({
  lineName: dep.short_name,
  direction: dep.headsign,
  time: DateTime.fromISO(dep.next_departure),
  departsIn: Duration.fromMillis(0),
});

export const removePassedDepartures = (
  departures: DepartureData,
  listMinimumTimeCutoff: number,
): DepartureData => {
  const now = DateTime.now().plus({ milliseconds: listMinimumTimeCutoff });
  const updated: DepartureData = {};
  Object.entries(departures).forEach(([stopId, deps]) => {
    for (let i = 0; i < deps.length; i++) {
      if (deps[i].time > now) {
        updated[stopId] = deps.slice(i);
        break;
      }
    }
  });
  return updated;
};

export const updateDepartsIn = (
  departures: DepartureData,
  listAmount: number,
): void => {
  Object.keys(departures).forEach((stopId) => {
    // Only need to update the first [listAmount] since we only show that many departures for each stop
    for (let j = 0; j < listAmount; j++) {
      if (departures[stopId][j]) {
        departures[stopId][j].departsIn = departures[stopId][j].time.diffNow();
      }
    }
  });
};

export interface ApiStop {
  stop_id: string;
  stop_name: string;
  stop_lat: number;
  stop_lon: number;
  location_type: null;
  geom: string;
}

export interface Stop {
  id: string;
  name: string;
  coordinate: { lng: number; lat: number };
}

export interface StopData {
  [stopId: string]: Stop;
}

export interface ApiDeparture {
  arrival: string;
  date: string;
  departure: string;
  headsign: string;
  id: string;
  lat: number;
  lon: number;
  long_name: string;
  name: string;
  next_arrival: string;
  next_departure: string;
  short_name: string;
}

export interface Departure {
  lineName: string;
  direction: string;
  time: DateTime;
  departsIn: Duration;
}

export interface DepartureWithStop extends Departure {
  stop: string;
}

export interface ApiDepartureData {
  [stopId: string]: ApiDeparture[];
}
export interface DepartureData {
  [stopId: string]: Departure[];
}
