import React, { FC, useMemo } from 'react';
import { NavLink, useMatch } from 'react-router-dom';
import { LinkProps } from '../../hooks/useLinks';
import { cn } from '../../utils/classNames';

export const BigButtonLink: FC<LinkProps & { linkCount: number }> = ({
  icon,
  label,
  to,
  linkCount,
}) => {
  const match = useMatch(to);
  const isActive = useMemo(() => match?.pathname === to, [match?.pathname, to]);

  const { height, width, iconHeight } = useMemo(() => {
    let height = 'h-[180px]';
    let iconHeight = 'size-14';
    const width = linkCount > 9 ? 'w-[250px]' : 'w-[320px]';
    if (linkCount > 6) {
      height = 'h-[120px]';
      iconHeight = 'size-10';
    } else if (linkCount > 3) {
      height = 'h-[160px]';
      iconHeight = 'size-12';
    }
    return { height, width, iconHeight };
  }, [linkCount]);

  return (
    <NavLink
      key={to}
      className={cn(
        'p-5 bg-white/10 transition-all rounded text-white justify-center backdrop-blur-lg gap-2 flex flex-col items-center',
        isActive && 'bg-white/30 border-2 border-white',
        height,
        width,
      )}
      to={to}
    >
      <span className={iconHeight}>{icon}</span>
      <span className={linkCount > 9 ? 'text-[18px]' : 'text-[22px]'}>
        {label}
      </span>
    </NavLink>
  );
};
