import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import {
  DepartureData,
  DepartureWithStop,
  StopData,
} from '../types/publicTransits';

interface UsePublicTransit {
  departures: DepartureData;
  sortedDepartures: DepartureWithStop[];
  update: (stops: StopData, newDepartures: DepartureData) => void;
}

export const usePublicTransit = create<UsePublicTransit>()(
  devtools(
    (set) => ({
      departures: {},
      sortedDepartures: [],
      update: (stops, newDepartures) => {
        const sorted = Object.entries(newDepartures)
          .flatMap(([stopId, deps]) =>
            deps
              .slice(0, 3) // Only taking first 3 as that is the number of departures shown in the soon departing list
              .map(
                (d) =>
                  ({ ...d, stop: stops[stopId].name }) as DepartureWithStop,
              ),
          )
          .sort((a, b) => a.time.toMillis() - b.time.toMillis());
        set({
          departures: newDepartures,
          sortedDepartures: sorted,
        });
      },
    }),
    {
      name: 'public-transit',
    },
  ),
);
