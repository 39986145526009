import React, { FC, useCallback } from 'react';
import { usePublicTransit } from '../../store/usePublicTransit';
import { FormattedMessage } from 'react-intl';
import { useLocationInternal } from '../../hooks/useLocation';
import { DepartureItem } from './DepartureItem';
import { NavLink } from 'react-router-dom';

interface Props {
  horizontal?: boolean;
}

const DepartsSoonList: FC<Props> = ({ horizontal }) => {
  const { data: location, isLoading } = useLocationInternal();
  const closestDepartures = usePublicTransit(
    useCallback(({ sortedDepartures }) => sortedDepartures.slice(0, 3), []),
  );

  if (isLoading || !location || closestDepartures.length === 0) {
    return null;
  }

  if (horizontal) {
    return (
      <NavLink
        to={'/publictransit'}
        className="flex h-full w-full items-center gap-6 bg-white/20 px-6 p-1"
      >
        <div className="flex h-full grow items-center justify-around gap-6">
          {closestDepartures?.map((dep, i) => (
            <div key={`departure-${i}`} className="flex items-center gap-2">
              <DepartureItem departure={dep} />
            </div>
          ))}
        </div>
      </NavLink>
    );
  }

  return (
    <NavLink
      to={'/publictransit'}
      className="m-2 grid grid-cols-[1fr_auto] gap-x-4 gap-y-1 space-y-2 font-small"
    >
      <span className="col-span-2 text-center caption">
        <FormattedMessage defaultMessage="Kommande planerade avgångar" />
      </span>
      {closestDepartures.map((dep, i) => (
        <DepartureItem key={`departure-${i}`} departure={dep} />
      ))}
    </NavLink>
  );
};

export default DepartsSoonList;
