import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import { useAttachment } from '../../hooks/useAttachments';
import { Attachment } from '../../types/attachments';
import { Spinner } from '../Spinner';
import { FormattedMessage } from 'react-intl';

interface Props {
  attachment: Attachment;
  onClick: () => void;
}

export const AttachmentsMenuItem: React.FC<Props> = ({
  attachment,
  onClick,
}) => {
  const { data } = useAttachment(attachment.id);
  const [numPages, setNumPages] = useState(0);

  const isLoaded = numPages !== 0;

  return (
    <article className="flexflex-col items-center ">
      <h2 className="mb-0 max-w-[258px] truncate text-lg">
        {attachment.name.replace('.pdf', '')}
      </h2>
      <div
        className={`relative flex w-[258px] rounded bg-gray-light p-1 ${
          isLoaded ? 'h-auto' : 'h-[361px]'
        }`}
        onClick={onClick}
      >
        {!isLoaded ? <Spinner className="m-auto" /> : null}
        {data ? (
          <Document
            className={`h-full w-full ${!isLoaded ? 'hidden' : ''}`}
            file={data}
            onLoadSuccess={({ numPages }) => setNumPages(numPages)}
            onLoadError={console.error}
            onSourceError={console.error}
            renderMode="canvas"
          >
            {isLoaded ? (
              <Page
                renderAnnotationLayer={false}
                renderTextLayer={false}
                pageNumber={1}
                width={250}
              />
            ) : null}
          </Document>
        ) : null}
        {numPages > 1 ? (
          <div className="absolute bottom-3 left-3 rounded bg-gray-200 px-2 py-1 text-sm">
            {numPages} <FormattedMessage defaultMessage="sidor" />
          </div>
        ) : null}
      </div>
    </article>
  );
};
