import {
  IconAntennaSignalTag,
  IconFlash,
  IconInfoCircle,
  IconLeaf,
  IconMediaVideo,
  IconNetwork,
  IconReports,
} from '@allbin/icons';
import { ApiEmbeddedUrlIcon } from '@allbin/viu-types';
import React from 'react';

export const EmbedIcon: React.FC<{ icon: ApiEmbeddedUrlIcon }> = ({ icon }) => {
  switch (icon) {
    case 'info':
      return <IconInfoCircle />;
    case 'energy':
      return <IconFlash />;
    case 'video':
      return <IconMediaVideo />;
    case 'stats':
      return <IconReports />;
    case 'eco':
      return <IconLeaf />;
    case 'signal':
      return <IconAntennaSignalTag />;
    case 'network':
      return <IconNetwork />;
    default:
      return <IconInfoCircle />;
  }
};
