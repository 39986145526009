import React, { FC } from 'react';
import { useLinks } from '../../hooks/useLinks';
import { BigButtonLink } from './BigButtonLink';

export const BigButtons: FC = () => {
  const links = useLinks();

  return (
    <div className="flex flex-wrap gap-2 content-center justify-center items-center">
      {links.map((link) => {
        return (
          <BigButtonLink key={link.to} {...link} linkCount={links.length} />
        );
      })}
    </div>
  );
};
