import React from 'react';
import { useAttachment } from '../../hooks/useAttachments';

import { Attachment } from '../../types/attachments';

interface AttachmentCacheItemProps {
  attachment: Attachment;
}

const AttachmentCacheItem: React.FC<AttachmentCacheItemProps> = ({
  attachment,
}) => {
  /* just here to keep the attachment cached */
  useAttachment(attachment.id);
  return null;
};

export default AttachmentCacheItem;
