import React from 'react';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';

interface QueryParams {
  type: string;
  id: string;
  psk: string;
}

export function useQueryParams(): QueryParams {
  const { search } = useLocation();
  return React.useMemo(() => {
    const params = new URLSearchParams(search);
    return {
      type: params.get('type') || '',
      id: params.get('id') || '',
      psk: params.get('psk') || '',
    };
  }, [search]);
}

export const LoginView: React.FC = () => {
  const [params] = useSearchParams();
  return <Navigate to={`/?${params.toString()}`} replace={true} />;
};

export default LoginView;
