import React, { useEffect, useState } from 'react';

interface Props {
  value?: string;
}

export const ErrorBar: React.FC<Props> = ({ value }) => {
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    if (value) {
      setLocalValue(value);
    }
  }, [value]);

  return (
    <div
      className="flex items-center justify-center overflow-hidden bg-red-dark text-white transition-all"
      style={{
        height: value ? '84px' : '0px',
      }}
    >
      <h3 className="max-h-full max-w-full overflow-hidden text-ellipsis whitespace-normal break-words text-center text-white">
        {localValue}
      </h3>
    </div>
  );
};

export default ErrorBar;
