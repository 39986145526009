import React from 'react';

interface Props {
  className?: string;
}

export const IconPartlyCloudy: React.FC<Props> = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_409_18902)">
        <path
          d="M8.5816 11.0623C9.95166 11.0623 11.0623 9.95166 11.0623 8.5816C11.0623 7.21154 9.95166 6.10089 8.5816 6.10089C7.21154 6.10089 6.10089 7.21154 6.10089 8.5816C6.10089 9.95166 7.21154 11.0623 8.5816 11.0623Z"
          fill="#FFD96E"
          stroke="#FFD96E"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3 8.5816H3.62018M8.5816 3V3.62018M13.543 8.5816H14.1632M8.5816 13.543V14.1632M4.61246 4.61246L5.04659 5.04659M12.5507 4.61246L12.1166 5.04659M12.1166 12.1166L12.5507 12.5507M5.04659 12.1166L4.61246 12.5507"
          stroke="#FFD96E"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.36495 20.3264C6.18641 20.3264 5.05614 19.8786 4.22279 19.0815C3.38944 18.2843 2.92126 17.2032 2.92126 16.0759C2.92126 14.9486 3.38944 13.8675 4.22279 13.0704C5.05614 12.2733 6.18641 11.8254 7.36495 11.8254C7.64329 10.5854 8.45757 9.49568 9.62865 8.79599C10.2085 8.44953 10.8585 8.20926 11.5415 8.08889C12.2246 7.96853 12.9273 7.97042 13.6095 8.09446C14.2917 8.2185 14.9401 8.46226 15.5176 8.81182C16.0952 9.16139 16.5906 9.60991 16.9755 10.1318C17.3605 10.6537 17.6274 11.2387 17.7612 11.8534C17.8949 12.4681 17.8928 13.1005 17.755 13.7145H18.6996C19.5764 13.7145 20.4172 14.0628 21.0372 14.6828C21.6572 15.3028 22.0055 16.1437 22.0055 17.0205C22.0055 17.8973 21.6572 18.7381 21.0372 19.3581C20.4172 19.9781 19.5764 20.3264 18.6996 20.3264H7.36495Z"
          fill="#E7E8E9"
          stroke="#E7E8E9"
          strokeWidth="1.41683"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_409_18902">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconPartlyCloudy;
