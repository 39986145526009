import React, { FC, useMemo } from 'react';
import { Announcement } from '../types/announcements';

interface AlertComponentProps {
  announcements: Pick<Announcement, 'message'>[];
}

const largeBreakpoint = 180;
const mediumBreakpoint = 50;

const AlertComponent: FC<AlertComponentProps> = ({ announcements }) => {
  const message = useMemo(() => {
    if (announcements.length === 0) {
      return '';
    }

    return announcements[0].message;
  }, [announcements]);

  const fontClass = useMemo(() => {
    if (message.length >= largeBreakpoint) {
      return 'body1';
    }
    if (message.length >= mediumBreakpoint) {
      return 'header3';
    }
    return 'header1';
  }, [message.length]);

  if (message.length === 0) {
    return null;
  }

  return (
    <div className="flex h-[144px] w-full shrink-0 flex-col items-center justify-center overflow-hidden bg-red-dark p-2 text-white">
      <h2 className={`w-full break-words text-center !font-bold ${fontClass}`}>
        {message}
      </h2>
    </div>
  );
};

export default AlertComponent;
