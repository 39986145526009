import { makeDeviceId, ApiDeviceType } from '@allbin/viu-api-client';
import React, { useCallback, useEffect, useMemo } from 'react';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import CoveringLoadingScreen from '../components/CoveringLoadingScreen';

interface IdentityData {
  id?: string;
  deviceId?: string;
  deviceType?: ApiDeviceType;
  apiKey?: string;
}

interface IdentityStore extends IdentityData {
  setId: (deviceId: string, deviceType: ApiDeviceType) => void;
  setApiKey: (apiKey: string) => void;
}

export const useIdentity = create<IdentityStore>()(
  devtools(
    persist(
      immer((set) => ({
        setApiKey: (apiKey) => {
          set((state) => {
            if (state.apiKey !== apiKey) {
              state.apiKey = apiKey;
            }
          });
        },
        setId: (deviceId, deviceType) =>
          set((state) => {
            if (!deviceType) {
              deviceType = 'eloview';
            }
            const id = makeDeviceId(deviceType, deviceId);

            if (state.deviceId !== deviceId) {
              state.deviceId = deviceId;
            }

            if (state.deviceType !== deviceType) {
              state.deviceType = deviceType;
            }

            if (state.id !== id) {
              console.log('setting id from', state.id, 'to', id);
              state.id = id;
            }
          }),
      })),
      {
        name: 'identity',
        partialize: (state) => ({
          id: state.id,
          deviceId: state.deviceId,
          deviceType: state.deviceType,
          apiKey: state.apiKey,
        }),
      },
    ),
    {
      name: 'identity',
    },
  ),
);

export const IdentityProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const params = useMemo(() => new URLSearchParams(window.location.search), []);
  const { id, setId, setApiKey } = useIdentity(
    useCallback(
      (state) => ({
        id: state.id,
        setId: state.setId,
        setApiKey: state.setApiKey,
      }),
      [],
    ),
  );

  useEffect(() => {
    const id = params.get('id');
    const deviceType = params.get('type') as ApiDeviceType;
    if (id && deviceType) {
      setId(id, deviceType);
    }

    const apiKey = params.get('psk');
    if (apiKey) {
      setApiKey(apiKey);
    }
  }, [params, setId, setApiKey]);

  if (!id) {
    console.log('no id');
    return <CoveringLoadingScreen />;
  }

  return <>{children}</>;
};
