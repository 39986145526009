import {
  ApiUnit,
  ApiUnitRequest,
  ApiResidentialTenant,
  ApiCompanyTenant,
  ApiTenant,
} from '@allbin/viu-api-client';
import { DateTime } from 'luxon';
import { Metadata, reviveMeta } from './metadata';

export interface ResidentialTenant
  extends Omit<ApiResidentialTenant, 'active_from' | 'active_to'> {
  type: 'individual';
  active_from?: DateTime;
  active_to?: DateTime;
}

export interface CompanyTenant
  extends Omit<ApiCompanyTenant, 'active_from' | 'active_to'> {
  type: 'company';
  active_from?: DateTime;
  active_to?: DateTime;
}

export type Tenant = ResidentialTenant | CompanyTenant;

const reviveTenant = (tenant: ApiTenant): Tenant => ({
  ...tenant,
  active_from: tenant.active_from
    ? DateTime.fromISO(tenant.active_from)
    : undefined,
  active_to: tenant.active_to ? DateTime.fromISO(tenant.active_to) : undefined,
});

export interface Unit extends Omit<ApiUnit, 'meta' | 'tenants'> {
  meta: Metadata;
  tenants: Tenant[];
}

export interface UnitRequest extends Omit<ApiUnitRequest, 'tenants'> {
  tenants: Tenant[];
}

export const reviveUnit = (unit: ApiUnit): Unit => ({
  ...unit,
  meta: reviveMeta(unit.meta),
  tenants: unit.tenants.map(reviveTenant),
});

export const serializeUnit = (unit: UnitRequest): ApiUnitRequest => ({
  ...unit,
  tenants: unit.tenants.map((tenant) => ({
    ...tenant,
    active_from: tenant.active_from?.toISO() || undefined,
    active_to: tenant.active_to?.toISO() || undefined,
  })),
});

export type TenantWithFloor = Tenant & {
  floor_nr: number;
  floor_label: string;
  object_id: string;
};

export type Demographic = 'companies' | 'individuals' | 'mixed';
