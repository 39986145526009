import { useQuery, UseQueryResult } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
import axios from 'axios';

interface EnvConfig {
  api: string;
  tenantViu: string;
  onboarding: string;
}

function isEnvValid({ api, tenantViu, onboarding }: EnvConfig): boolean {
  if (typeof api !== 'string' || !api.startsWith('http')) {
    return false;
  }

  if (typeof tenantViu !== 'string' || !tenantViu.startsWith('http')) {
    return false;
  }

  if (typeof onboarding !== 'string' || !onboarding.startsWith('http')) {
    return false;
  }

  return true;
}

export const useEnv = (): UseQueryResult<EnvConfig> & Partial<EnvConfig> => {
  const query = useQuery({
    queryKey: ['env'],
    queryFn: async () => {
      const response = await axios.get<EnvConfig>('/env.json');
      const conf = response.data;

      if (!isEnvValid(conf)) {
        throw new Error('invalid environment: ' + conf);
      }

      Sentry.addBreadcrumb({
        message: 'Configured environment',
        category: 'env',
        data: conf,
      });

      return conf;
    },
  });

  return { ...query, ...query.data };
};
