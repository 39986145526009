import { useCallback, useEffect } from 'react';
import {
  Map,
  map,
  CRS,
  LatLngBounds,
  imageOverlay,
  geoJSON,
  icon,
  marker,
  Layer,
  LatLng,
  Marker,
  circleMarker,
  divIcon,
} from 'leaflet';
import { Feature, GeoJsonObject } from 'geojson';
import { CompanyTenant, Unit } from '../../types/unit';
import { Floor } from '../../types/floor';
import { useUnits } from '../../hooks/useUnits';
import { useFeatures } from '../../hooks/useFeatures';
import home from '../../images/home.png';
import meetingRoom from '../../images/meeting-room.png';
import {
  isCurrentlyUsed,
  useBookingResourcesWithEvents,
} from '../../hooks/useBookingResourcesWithEvents';
import { FeatureCollection } from '@allbin/viu-types/lib/geojson';
import { getCompanyShortName } from '../../utils/helpers';
import { cn } from '../../utils/classNames';
import { useUnitsServices } from '../../hooks/useUnitsServices';

interface AdvancedLayer extends Layer {
  feature?: Feature;
  _bounds: LatLngBounds;
}

let mapRef: Map | null = null;

const useFloorPlanMap = (
  highlightUnitId: string,
  onFeatureClick: (feature?: Feature) => void,
  floor?: Floor,
): void => {
  const { data: units } = useUnits();
  // const { data: services } = useServices();
  const { data: features } = useFeatures();
  const unitsServices = useUnitsServices();

  const { data: resources_by_service_id = {} } =
    useBookingResourcesWithEvents();

  const addLogoToMap = useCallback(
    (options: {
      logo?: string;
      letters?: string;
      x: number;
      y: number;
      unit?: Unit;
      size?: number;
    }): Marker => {
      if (!mapRef) {
        return {} as Marker;
      }

      const { logo, letters: letter = '', x, y, unit, size = 48 } = options;

      const otherUnitIsHighlighted =
        unit && highlightUnitId !== '' && highlightUnitId !== unit?.id;

      let className = 'rounded';
      if (otherUnitIsHighlighted) {
        className += ' opacity-50';
      }

      const companyLogo = logo
        ? icon({
            iconUrl: logo,
            iconSize: [size, size],
            iconAnchor: [size / 2, size / 2],
            className,
          })
        : divIcon({
            html: `<p>${letter}</p>`,
            className: cn(
              '!-ml-[20px] !-mt-[20px] flex bg-white !size-12 rounded justify-center items-center border border-gray',
              letter?.length > 2 ? 'text-xl' : 'text-2xl',
              otherUnitIsHighlighted && 'opacity-50',
            ),
          });
      return marker([y, x], { icon: companyLogo, interactive: false }).addTo(
        mapRef,
      );
    },
    [highlightUnitId],
  );

  const addCompanyLogos = useCallback(
    (layer: AdvancedLayer, unit?: Unit): void => {
      if (!unit) {
        return;
      }

      const companies = (
        unit.tenants.filter((t) => t.type === 'company') as CompanyTenant[]
      ).sort((a, b) =>
        a.company.name
          .toLowerCase()
          .localeCompare(b.company.name.toLowerCase()),
      );
      if (companies.length === 0) {
        return;
      }

      const center = layer._bounds.getCenter();
      if (companies.length === 1) {
        addLogoToMap({
          logo: companies[0].company.logo,
          letters: getCompanyShortName(companies[0]),
          x: center.lng,
          y: center.lat,
          unit,
        });
        return;
      }

      const radius = 50 + unit.tenants.length * 15;
      const step = (2 * Math.PI) / companies.length;
      let angle = step;
      companies.forEach((t) => {
        const x = center.lng + radius * Math.cos(angle);
        const y = center.lat + radius * Math.sin(angle);
        addLogoToMap({
          logo: t.company.logo,
          letters: getCompanyShortName(t),
          x,
          y,
          unit,
        });
        angle -= step;
      });
    },
    [addLogoToMap],
  );

  const addGeoJSON = useCallback(
    (geoJson: GeoJsonObject): void => {
      if (!mapRef || !units) {
        return;
      }

      const geoJSONLayer = geoJSON(geoJson, {
        pointToLayer: (feature: Feature, latlng: LatLng) => {
          if (feature.properties?.['type'] === 'wallpad') {
            return addLogoToMap({
              logo: home,
              x: latlng.lng,
              y: latlng.lat,
              size: 30,
            });
          }
          return circleMarker(latlng, { fillOpacity: 0, opacity: 0 });
        },
        onEachFeature: (feature, layer) => {
          layer.on('click', () => {
            onFeatureClick(feature);
          });
        },
        style: (feature) => {
          if (feature?.geometry.type !== 'Polygon') {
            return {};
          }

          const unit = units.find(
            (u) => u.id === feature?.properties?.['unit_id'],
          );
          if (!unit) {
            return { color: '#ff0000', fillOpacity: 1 };
          }

          const services = unitsServices[unit.id];

          if (services.length > 0) {
            const resources = services.flatMap(
              (s) => resources_by_service_id[s.id],
            );

            const allUsed = resources.every((r) => isCurrentlyUsed(r));
            const someUsed = resources.some((r) => isCurrentlyUsed(r));

            return {
              color: allUsed ? '#ce330c' : someUsed ? '#acaa0c' : '#239929',
              weight: 5,
            };
          }

          if (highlightUnitId !== '') {
            if (highlightUnitId === feature?.properties?.['unit_id']) {
              return {
                color: '#0ab0f5',
                fillColor: '#0ab0f5',
                fillOpacity: 1,
                className: 'animate-pulse',
                weight: 5,
              };
            } else {
              return {
                color: '#0ab0f5',
                weight: 5,
              };
            }
          }
          return { color: '#0ab0f5', weight: 5 };
        },
      }).addTo(mapRef);

      (geoJSONLayer.getLayers() as AdvancedLayer[]).forEach((layer) => {
        const unit = units.find(
          (u) => u.id === layer.feature?.properties?.unit_id,
        );

        if (!unit) {
          return;
        }

        const services = unitsServices[unit.id];

        if (unit && services.length > 0) {
          const center = layer._bounds.getCenter();
          addLogoToMap({
            logo: meetingRoom,
            x: center.lng,
            y: center.lat,
            size: 48,
          });
        } else {
          addCompanyLogos(layer, unit);
        }
      });
    },
    [
      addCompanyLogos,
      addLogoToMap,
      highlightUnitId,
      onFeatureClick,
      resources_by_service_id,
      units,
      unitsServices,
    ],
  );

  const initializeMap = useCallback(
    async (url: string, geojson: GeoJsonObject): Promise<Map> => {
      const mapElement = document.getElementById(
        'leafletmap',
      ) as HTMLDivElement;

      const { w, h } = await getImageDimensions(url);

      mapRef = map(mapElement, {
        crs: CRS.Simple,
        minZoom: -3,
        maxZoom: 3,
        zoom: -1,
        zoomSnap: 0.33,
        center: [h, w],
        zoomControl: false,
        attributionControl: false,
      });

      const southWest = mapRef.unproject([0, h], 0);
      const northEast = mapRef.unproject([w, 0], 0);
      const bounds = new LatLngBounds(southWest, northEast);

      imageOverlay(url, bounds).addTo(mapRef);

      mapRef.fitBounds(bounds);

      addGeoJSON(geojson);
      return mapRef;
    },
    [addGeoJSON],
  );

  useEffect(() => {
    if (!floor?.floor_plan || !units || units.length === 0 || !features) {
      return;
    }

    const floorFeatures: FeatureCollection = {
      type: 'FeatureCollection',
      features: features.features.filter(
        (f) => f.properties?.floor_id === floor.id,
      ),
    };

    const loadMap = async (): Promise<void> => {
      if (!floor.floor_plan) {
        return;
      }

      mapRef = await initializeMap(floor.floor_plan, floorFeatures);
    };

    void loadMap();

    return () => {
      if (!mapRef) {
        return;
      }

      mapRef.off();
      mapRef.remove();
    };
  }, [floor, initializeMap, units, features]);
};

const getImageDimensions = (url: string): Promise<{ h: number; w: number }> =>
  new Promise<{ h: number; w: number }>((resolve, reject) => {
    let w = 0,
      h = 0;
    const tempImgElement = document.createElement('img');
    tempImgElement.src = url;
    tempImgElement.onload = () => {
      w = tempImgElement.naturalWidth;
      h = tempImgElement.naturalHeight;
      resolve({ w, h });
    };
    tempImgElement.onerror = () => {
      reject(new Error('Could not load image'));
    };
  });

export default useFloorPlanMap;
