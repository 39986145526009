import { DateTime } from 'luxon';
import { Weather } from '../hooks/weather/weather';

export const secondsToMidday = (time: DateTime): number => {
  const midday = time.startOf('day').plus({ hour: 12 });
  return Math.abs(time.diff(midday).as('seconds'));
};

export const dayForecast = (
  day: DateTime,
  forecast: Weather[],
): Weather | undefined => {
  return forecast
    .filter((w) => w.time.toISODate() === day.toISODate())
    .reduce<Weather | undefined>((closest, weather) => {
      if (
        !closest ||
        secondsToMidday(closest.time) > secondsToMidday(weather.time)
      ) {
        return weather;
      }

      return closest;
    }, undefined);
};
