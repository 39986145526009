import { IconSort, IconSortDown, IconSortUp } from '@allbin/icons';
import React, { FC } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { SortDirection, TenantListSortOption } from '../../utils/TenantUtils';

interface TenantToolbarProps {
  onSortClick: (sort: TenantListSortOption) => void;
  /** Default: floor */
  sort?: TenantListSortOption;
  sortDirection?: SortDirection;
  onlyCompanies?: boolean;
}

const sortOptionsCompanies: TenantListSortOption[] = ['floor', 'name'];

const sortOptionsMixed: TenantListSortOption[] = ['floor', 'name', 'last_name'];

const messages = defineMessages({
  name: {
    id: 'name',
    defaultMessage: 'namn',
  },
  last_name: {
    id: 'last_name',
    defaultMessage: 'efternamn',
  },
  floor: {
    id: 'floor',
    defaultMessage: 'våningsplan',
  },
});

const TenantToolbar: FC<TenantToolbarProps> = ({
  sort = 'floor',
  onSortClick,
  sortDirection,
  onlyCompanies,
}) => {
  const intl = useIntl();
  const options = onlyCompanies ? sortOptionsCompanies : sortOptionsMixed;

  return (
    <div className="flex items-center gap-1 pb-3 pl-9 leading-6">
      {options.map((sortOption) => {
        const selected = sort === sortOption;
        let icon = <IconSort className="h-6 w-6 scale-y-[-1]" />;
        if (selected) {
          if (sortDirection === 'asc') {
            icon = <IconSortDown className="h-6 w-6" />;
          } else {
            icon = <IconSortUp className="h-6 w-6" />;
          }
        }

        return (
          <button
            onClick={() => onSortClick(sortOption)}
            key={sortOption}
            className={`flex gap-2 rounded px-4 py-2 active:bg-gray-100 ${
              selected ? 'text-gray-dark' : 'text-gray-medium'
            }`}
          >
            {icon}
            <span className="capitalize caption">
              {intl.formatMessage(messages[sortOption])}
            </span>
          </button>
        );
      })}
    </div>
  );
};

export default TenantToolbar;
