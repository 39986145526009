import { ApiDevice, ApiDeviceStatus } from '@allbin/viu-api-client';
import { DateTime } from 'luxon';
import { Metadata, reviveMeta } from './metadata';

export const reviveDevice = (device: ApiDevice): Device => ({
  ...device,
  meta: reviveMeta(device.meta),
  status: reviveDeviceStatus(device.status),
  license_expiry: device.license_expiry
    ? DateTime.fromISO(device.license_expiry)
    : undefined,
  warranty_expiry: device.warranty_expiry
    ? DateTime.fromISO(device.warranty_expiry)
    : undefined,
});

export interface DeviceStatus extends Omit<ApiDeviceStatus, 'last_seen'> {
  last_seen?: DateTime;
}

export const reviveDeviceStatus = (
  deviceStatus: ApiDeviceStatus,
): DeviceStatus => ({
  ...deviceStatus,
  last_seen: deviceStatus.last_seen
    ? DateTime.fromISO(deviceStatus.last_seen)
    : undefined,
});

export interface Device
  extends Omit<
    ApiDevice,
    'meta' | 'status' | 'license_expiry' | 'warranty_expiry'
  > {
  meta: Metadata;
  status: DeviceStatus;
  license_expiry?: DateTime;
  warranty_expiry?: DateTime;
}
