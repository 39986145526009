import { ApiAttachment } from '@allbin/viu-api-client';
import { DateTime } from 'luxon';
import { Metadata, reviveMeta } from './metadata';

export const reviveAttachment = (attachment: ApiAttachment): Attachment => ({
  ...attachment,
  meta: reviveMeta(attachment.meta),
  active_from: attachment.active_from
    ? DateTime.fromISO(attachment.active_from)
    : undefined,
  active_to: attachment.active_to
    ? DateTime.fromISO(attachment.active_to)
    : undefined,
});

export interface Attachment
  extends Omit<ApiAttachment, 'meta' | 'active_from' | 'active_to'> {
  meta: Metadata;
  active_from?: DateTime;
  active_to?: DateTime;
}
