import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';

export interface VersionState {
  version: string;
}

let currentVersion = '';

export const useVersion = (): VersionState & UseQueryResult<VersionState> => {
  const query = useQuery({
    queryKey: ['version'],
    queryFn: async () => {
      Sentry.addBreadcrumb({
        message: 'Checking for new version',
        category: 'version',
      });
      const res = await axios.get<VersionState>('/version.json');
      if (res.status !== 200) {
        throw new Error('Failed to fetch version');
      }

      Sentry.addBreadcrumb({
        message: 'Version check completed',
        category: 'version',
        data: res.data,
      });
      return res.data;
    },
    refetchInterval: 10 * 60 * 1000,
    retry: true,
    retryDelay: 30 * 1000,
  });

  useEffect(() => {
    if (!query.isFetched || !query.data?.version) {
      return;
    }

    if (!currentVersion) {
      currentVersion = query.data.version;
      document.cookie = `page_loaded=${Date.now()}; path=/;`;

      Sentry.addBreadcrumb({
        message: 'Initial version check completed',
        category: 'version',
        data: query.data,
      });

      return;
    }

    if (currentVersion === query.data.version) {
      return;
    }

    Sentry.addBreadcrumb({
      message: 'New version detected, reloading page',
      category: 'version',
      data: { currentVersion, newVersion: query.data },
    });

    // Delete cookie before reload
    document.cookie =
      'page_loaded=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT;';
    console.warn(
      'New version detected, reloading page',
      `${currentVersion} -> ${query.data.version}`,
    );

    window.location.reload();
  }, [query.data, query.isFetched]);

  return { ...query, version: query.data?.version || '' };
};
