import { RequestHandler, rest } from 'msw';
import { ddApiDevice } from '../gallery/dummydata/ddDevice';
import { ddDMSConfig } from '../gallery/dummydata/ddDMSConfig';
import { ddApiLocation } from '../gallery/dummydata/ddLocations';
import { handlersGtfs } from './handlers-gtfs';
import { handlersSmhi } from './handlers-smhi';

export const handlers: RequestHandler[] = [
  ...handlersSmhi,
  ...handlersGtfs,

  rest.get(
    'https://api.viu.dev.allbin.se/public/devices/:deviceId',
    (req, res, ctx) => {
      return res(ctx.json(ddApiDevice));
    },
  ),

  rest.get(
    `https://api.viu.dev.allbin.se/public/devices/:deviceId/config`,
    (req, res, ctx) => res(ctx.json(ddDMSConfig)),
  ),

  rest.get(
    `https://api.viu.dev.allbin.se/public/devices/:deviceId/location`,
    (req, res, ctx) => res(ctx.json(ddApiLocation)),
  ),

  rest.get(
    `https://api.viu.dev.allbin.se/public/devices/:deviceId/announcements`,
    (req, res, ctx) => res(ctx.json([])), // TODO ddApiAnnouncements
  ),

  rest.get(
    `https://api.viu.dev.allbin.se/public/devices/:deviceId/attachments`,
    (req, res, ctx) => res(ctx.json([])), // TODO ddApiAttachments
  ),

  rest.get(
    `https://api.viu.dev.allbin.se/public/devices/:deviceId/embedded-urls`,
    (req, res, ctx) => res(ctx.json([])), // TODO ddApiEmbeddedUrls
  ),
];
