import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useVersion } from '../hooks/useVersion';
import logo from '../icons/logo.svg';
import background from '../icons/tenant-viu-background.webp';

interface Props {
  message?: string | React.ReactNode;
  progressBar?: boolean;
  action?: React.ReactNode;
}

const CoveringLoadingScreen: FC<Props> = ({ message, progressBar, action }) => {
  const { version } = useVersion();
  return (
    <main
      className="relative flex h-full w-full items-center justify-center bg-cover"
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className="absolute inset-0 bg-white/80" />
      <div className="relative flex flex-col items-center justify-center gap-8 bg-cover">
        <img src={logo} alt="Logo" className="relative h-[200px] w-[500px]" />
        <h2 className="text-green-dark">
          {message || (
            <FormattedMessage defaultMessage="Söker efter konfiguration..." />
          )}
        </h2>
        {progressBar && (
          <div className="h-2.5 w-full overflow-hidden rounded-full bg-green/20">
            <div className="h-full w-full origin-left animate-linear-progress bg-green-dark"></div>
          </div>
        )}
        {action}
      </div>
      {version && (
        <div className="absolute bottom-4 text-center">
          <span className="text-gray">Version: {version}</span>
        </div>
      )}
    </main>
  );
};

export default CoveringLoadingScreen;
