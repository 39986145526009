import { UseQueryResult, useQuery } from '@tanstack/react-query';
import {
  ApiDepartureData,
  DepartureData,
  reviveDeparture,
} from '../types/publicTransits';
import useConfig from './useConfig';
import { usePublicTransitStops } from './usePublicTransitStops';
import axios from 'axios';
import { useMemo } from 'react';
import { useIdentity } from '../providers/IdentityProvider';

export const usePublicTransitDepartures = (): UseQueryResult<DepartureData> => {
  const {
    apis: {
      publicTranitsQuery: { baseUrl, departureFetchInterval },
    },
  } = useConfig();
  const { data: stops } = usePublicTransitStops();
  const { id } = useIdentity();

  const stopIds = useMemo(() => {
    if (!stops) {
      return [];
    }

    return Object.keys(stops);
  }, [stops]);

  return useQuery({
    queryKey: ['publicTransit', 'departures', stopIds, baseUrl, id],
    queryFn: async (): Promise<DepartureData> => {
      if (stopIds.length === 0) {
        return Promise.resolve({});
      }

      const res = await axios.request<ApiDepartureData>({
        url: `${baseUrl}/departures`,
        method: 'get',
        params: {
          stop_id: stopIds.join(','),
          origin: id,
        },
      });

      const data: DepartureData = {};
      stopIds.forEach(
        (id) => (data[id] = res.data[id].map((d) => reviveDeparture(d))),
      );

      return data;
    },
    refetchInterval: departureFetchInterval,
    enabled: !!stops,
  });
};
