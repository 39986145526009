import React from 'react';

interface Props {
  className?: string;
}

const ViuLogo: React.FC<Props> = ({ className }) => (
  <svg
    viewBox="0 0 34 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMinYMin"
    className={className}
  >
    <path
      d="M11.7094 17.6425C11.5159 17.6425 11.342 17.525 11.2703 17.3458L7.60357 8.17406C7.47986 7.86463 7.70851 7.52842 8.04265 7.52842H9.57828C9.77828 7.52842 9.95667 7.65378 10.0243 7.8414C10.1776 8.26643 10.4509 9.02105 10.8443 10.1053C11.3736 11.5409 11.7244 12.5195 11.8967 13.041C12.1329 13.7267 12.3244 14.2998 12.4713 14.7602C12.5026 14.8582 12.7842 14.8522 12.8106 14.7528C12.9153 14.3601 13.0137 13.992 13.1061 13.6484C13.1929 13.34 13.251 13.1389 13.2804 13.0452C13.2871 13.0239 13.2944 13.0035 13.3016 12.9823C13.3583 12.8154 13.6347 11.9791 14.1308 10.4734C14.561 9.18195 14.8569 8.30329 15.0184 7.8374C15.083 7.65097 15.2592 7.52842 15.457 7.52842H16.9915C17.3221 7.52842 17.5506 7.85816 17.4335 8.16642L13.9509 17.3381C13.8814 17.5213 13.7054 17.6425 13.5089 17.6425H11.7094Z"
      fill="currentColor"
    />
    <path
      d="M19.7717 17.6425C19.5107 17.6425 19.2991 17.4316 19.2991 17.1713V7.99961C19.2991 7.73938 19.5107 7.52842 19.7717 7.52842H21.2451C21.5062 7.52842 21.7178 7.73938 21.7178 7.99961V17.1713C21.7178 17.4316 21.5062 17.6425 21.2451 17.6425H19.7717Z"
      fill="currentColor"
    />
    <path
      d="M32.2971 16.8511C31.4909 17.5075 30.4569 17.6425 29.1399 17.6425C27.8228 17.6425 26.7335 17.5075 25.9272 16.8511C25.1271 16.1946 24.7271 15.2313 24.7271 13.9613V7.99961C24.7271 7.73938 24.9387 7.52842 25.1998 7.52842H26.6824C26.9434 7.52842 27.1551 7.73938 27.1551 7.99961V13.8693C27.1551 14.6239 27.312 15.17 27.6259 15.5074C27.9459 15.8449 28.4506 15.9663 29.1399 15.9663C29.8169 15.9663 30.3092 15.8479 30.617 15.5166C30.9308 15.1792 31.0878 14.6301 31.0878 13.8693V7.99961C31.0878 7.73938 31.2994 7.52842 31.5604 7.52842H33.0338C33.2949 7.52842 33.5065 7.73938 33.5065 7.99961V13.9613C33.5065 15.2252 33.1034 16.1885 32.2971 16.8511Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0H21.6993V2.52632H2.92688C2.70392 2.52632 2.52317 2.70729 2.52317 2.93053V21.0695C2.52317 21.2927 2.70392 21.4737 2.92688 21.4737H21.6993V24H0V0Z"
      fill="currentColor"
    />
  </svg>
);

export default ViuLogo;
