import { Unit, reviveUnit } from '../types/unit';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useApiClient } from './useApiClient';
import { useIdentity } from '../providers/IdentityProvider';

export const useUnits = (): UseQueryResult<Unit[]> => {
  const apiClient = useApiClient();
  const { id } = useIdentity();
  const query = useQuery({
    queryKey: ['units', id],
    queryFn: async ({ queryKey: [, id] }) =>
      await apiClient.public.devices
        .getUnits(id ?? '')
        .then((units) => units.map((unit) => reviveUnit(unit))),
    enabled: true,
  });

  return query;
};
