import { DateTime } from 'luxon';
import {
  ApiDeparture,
  ApiDepartureData,
  ApiStop,
} from '../../types/publicTransits';

export const ddStops: ApiStop[] = [
  {
    geom: '0101000020E610000026396057932F2F4008CA6DFB1E174C40',
    location_type: null,
    stop_id: '740032188',
    stop_lat: 56.180633,
    stop_lon: 15.592921,
    stop_name: 'Karlskrona campus Gräsvik',
  },
  {
    geom: '0101000020E6100000888043A852332F40618907944D174C40',
    location_type: null,
    stop_id: '740031781',
    stop_lat: 56.182055,
    stop_lon: 15.60024,
    stop_name: 'Bergåsa centrum',
  },
];

function generateDepartures(
  shortName: string,
  headsignA: string,
  headsignB: string,
): ApiDeparture[] {
  const date = DateTime.now().plus({ minute: (Math.random() + 1) * 10 });
  const departures: ApiDeparture[] = [];
  for (let i = 0; i < 6; i++) {
    const depDateA = date.plus({ minute: i * 30 });
    const depDateB = date.plus({ minute: 30 + i * 20 });
    departures.push({
      id: `${i.toString()}_1`,
      departure: depDateA.toFormat('HH:mm:ss'),
      arrival: depDateA.toFormat('HH:mm:ss'),
      date: depDateA.toISO() ?? '',
      headsign: headsignA,
      short_name: shortName,
      long_name: '',
      lat: 0,
      lon: 0,
      name: '',
      next_arrival: '',
      next_departure: '',
    });
    departures.push({
      id: `${i.toString()}_2`,
      departure: depDateA.toFormat('HH:mm:ss'),
      arrival: depDateA.toFormat('HH:mm:ss'),
      date: depDateB.toISO() ?? '',
      headsign: headsignB,
      short_name: shortName,
      long_name: '',
      lat: 0,
      lon: 0,
      name: '',
      next_arrival: '',
      next_departure: '',
    });
  }

  return departures;
}

export const ddDepartures: ApiDepartureData = {
  '740032188': generateDepartures('1', 'Saltö', 'Lyckeby Slottsbacken'),
  '740031781': generateDepartures('3', 'Testvägen', 'Kungsmarken C-huset'),
};
