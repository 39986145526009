import React, { ReactNode, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import useConfig from '../../hooks/useConfig';
import { Departure } from '../../types/publicTransits';
import { cn } from '../../utils/classNames';

interface Props {
  departure: Departure;
  backgroundIsDark?: boolean;
}

export const DepartureRow: React.FC<Props> = ({
  departure,
  backgroundIsDark,
}) => {
  const {
    departures: { listRelativeTimeThreshold },
  } = useConfig();

  const departureTime = useMemo<ReactNode>(() => {
    const diffNow = departure.departsIn;
    if (
      diffNow.as('milliseconds') > listRelativeTimeThreshold ||
      diffNow.as('milliseconds') < 0 // needed for times past midnight
    ) {
      return departure.time.toLocal().toFormat('HH:mm');
    }

    return (
      <span>
        {Math.round(diffNow.as('minutes'))}
        <span
          className={cn(
            'ml-1 font-small',
            backgroundIsDark ? 'text-gray-300' : 'text-gray-600',
          )}
        >
          <FormattedMessage
            defaultMessage="min"
            description="Short for minutes"
          />
        </span>
      </span>
    );
  }, [
    backgroundIsDark,
    departure.departsIn,
    departure.time,
    listRelativeTimeThreshold,
  ]);

  return (
    <>
      <div className="flex items-center justify-start">
        <span className="rounded-full bg-green px-2 text-white">
          {departure.lineName.substring(0, 3)}
        </span>
      </div>
      <span
        className={cn(
          'body1',
          backgroundIsDark ? 'text-gray-100' : 'text-gray-800',
        )}
      >
        {departure.direction}
      </span>
      <span
        className={cn(
          'body1',
          backgroundIsDark ? 'text-gray-100' : 'text-gray-800',
        )}
      >
        {departureTime}
      </span>
    </>
  );
};

export default DepartureRow;
