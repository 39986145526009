import React, { useCallback, useEffect } from 'react';
import { usePublicTransitStops } from '../hooks/usePublicTransitStops';
import { usePublicTransit } from '../store/usePublicTransit';
import { useConfig } from './ConfigProvider';
import { usePublicTransitDepartures } from '../hooks/usePublicTransitDepartures';
import {
  removePassedDepartures,
  updateDepartsIn,
} from '../types/publicTransits';

export const PublicTransitUpdater: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const {
    departures: { listAmount, listUpdateInterval, listMinimumTimeCutoff },
  } = useConfig();
  const { data: stops } = usePublicTransitStops();
  const { data: allDepartures } = usePublicTransitDepartures();
  const { update, departures } = usePublicTransit(
    useCallback(({ update, departures }) => ({ update, departures }), []),
  );

  const handleTick = useCallback(() => {
    if (!stops) {
      return;
    }

    const updated = removePassedDepartures(departures, listMinimumTimeCutoff);
    updateDepartsIn(updated, listAmount);
    update(stops, updated);
  }, [stops, departures, listAmount, listMinimumTimeCutoff, update]);

  useEffect(() => {
    const interval = setInterval(handleTick, listUpdateInterval);
    return () => clearInterval(interval);
  }, [handleTick, listUpdateInterval]);

  useEffect(() => {
    if (!allDepartures || !stops) {
      return;
    }
    const updated = removePassedDepartures(
      allDepartures,
      listMinimumTimeCutoff,
    );
    updateDepartsIn(updated, listAmount);
    update(stops, updated);
  }, [stops, allDepartures, update, listAmount, listMinimumTimeCutoff]);

  return <>{children}</>;
};

export default PublicTransitUpdater;
