import React, { FC, Fragment } from 'react';
import { Company } from '../../types/company';
import { Modal } from '../Modal';
import { useInteractionTimeout } from '../../hooks/useInteractionTimeout';
import { useConfig } from '../../providers/ConfigProvider';
import { CompanyDetails } from '../CompanyDetails';

interface Props {
  companies: Company[];
  show: boolean;
  onClose: () => void;
}

export const CompaniesModal: FC<Props> = ({ companies, show, onClose }) => {
  const { interactionTimeout } = useConfig();
  useInteractionTimeout(interactionTimeout - 2000, onClose);

  if (!show) {
    return null;
  }

  return (
    <Modal open={true} onClose={onClose} className="w-4/5">
      <div className="flex w-full flex-col gap-12">
        {companies
          .slice()
          .sort((a, b) =>
            a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
          )
          .map((company, index) => (
            <Fragment key={company.name}>
              {index !== 0 ? (
                <div className="mx-auto h-1 w-11/12 rounded bg-gray-200" />
              ) : null}
              <CompanyDetails company={company} />
            </Fragment>
          ))}
      </div>
    </Modal>
  );
};
