import { Floor } from '../types/floor';
import { TenantWithFloor, Unit } from '../types/unit';

type TenantValueExtractor<T> = (tenant: TenantWithFloor) => T;

type TenantSorter = (
  a: TenantWithFloor,
  b: TenantWithFloor,
  dir: number,
) => number;
type TenantGrouper = (tenant: TenantWithFloor) => string;

const sortByString =
  (extractor: TenantValueExtractor<string>): TenantSorter =>
  (a, b, dir = 1) =>
    dir * extractor(a).localeCompare(extractor(b), 'sv');

const sortByNumber =
  (extractor: TenantValueExtractor<number>): TenantSorter =>
  (a, b, dir = 1): number => {
    if (extractor(a) === extractor(b)) {
      return 0;
    }

    if (Number.isNaN(extractor(a))) {
      return 1;
    }

    if (Number.isNaN(extractor(b))) {
      return -1;
    }

    return dir * (extractor(a) < extractor(b) ? -1 : 1);
  };

const groupByName =
  (extractor: TenantValueExtractor<string>): TenantGrouper =>
  (tenant) =>
    extractor(tenant).charAt(0).toUpperCase();
const groupByFullName =
  (extractor: TenantValueExtractor<string>): TenantGrouper =>
  (tenant) =>
    extractor(tenant);

type TenantSortersAndGroupers = Record<
  TenantListSortOption,
  {
    sorters: TenantSorter[];
    grouper: TenantGrouper;
  }
>;

const tenantSortersAndGroupers: TenantSortersAndGroupers = {
  name: {
    sorters: [
      sortByString((t) =>
        t.type === 'company'
          ? t.company.name
          : (t.individual.first_name || t.individual.last_name) ?? '',
      ),
      sortByString((t) =>
        t.type === 'company' ? t.company.name : t.individual.last_name ?? '',
      ),
      sortByNumber((t) => t.floor_nr),
    ],
    grouper: groupByName((t) =>
      t.type === 'company'
        ? t.company.name
        : (t.individual.first_name || t.individual.last_name) ?? '',
    ),
  },
  last_name: {
    sorters: [
      sortByString((t) =>
        t.type === 'company'
          ? t.company.name
          : t.individual.last_name ?? t.individual.first_name,
      ),
      sortByString((t) =>
        t.type === 'company' ? t.company.name : t.individual.first_name,
      ),
      sortByNumber((t) => t.floor_nr),
    ],
    grouper: groupByName((t) =>
      t.type === 'company'
        ? t.company.name
        : t.individual.last_name ?? t.individual.first_name,
    ),
  },
  floor: {
    sorters: [
      sortByNumber((t) => t.floor_nr),
      sortByString((t) =>
        t.type === 'company' ? '100000' : t.object_id || '',
      ),
      sortByString((t) =>
        t.type === 'company' ? t.company.name : t.individual.last_name ?? '',
      ),
    ],
    grouper: groupByFullName(
      (t) =>
        `${t.floor_nr.toString()}${t.floor_label ? ` ${t.floor_label}` : ''}`,
    ),
  },
};

export const sortAndGroupTenants = (
  tenants: TenantWithFloor[],
  sortField: TenantListSortOption,
  sortDirection: SortDirection = 'asc',
): TenantGrouping[] => {
  const sortDir = sortDirection === 'asc' ? 1 : -1;
  const { sorters, grouper } = tenantSortersAndGroupers[sortField];
  if (!sorters) {
    throw new Error(`No tenant sorters defined for '${sortField}'`);
  }
  if (!grouper) {
    throw new Error(`No tenant grouper defined for '${sortField}'`);
  }
  const sortedTenants = [...tenants].sort((a, b) =>
    sorters.reduce((ret, sorter, i) => {
      if (ret !== 0) return ret;

      return sorter(a, b, i === 0 ? sortDir : 1);
    }, 0),
  );

  const grouped = sortedTenants.reduce<TenantGrouping[]>((groups, tenant) => {
    const key = grouper(tenant);

    const lastItem = groups[groups.length - 1];
    if (!lastItem || lastItem.group !== key) {
      groups.push({ group: key, tenants: [tenant] });
    } else {
      lastItem.tenants.push(tenant);
    }
    return groups;
  }, []);
  return grouped;
};

export type TenantListSortOption = 'name' | 'last_name' | 'floor';
export type SortDirection = 'asc' | 'desc';

export interface TenantGrouping {
  group: string;
  tenants: TenantWithFloor[];
}

export const getTenantsWithFloor = (
  units: Unit[],
  floors: Floor[],
): TenantWithFloor[] => {
  return units.flatMap((unit) =>
    unit.tenants.map((tenant) => {
      const floor = floors.find((floor) => floor.id === unit.floor_id);
      return {
        ...tenant,
        floor_nr: floor?.level ?? 0,
        floor_label: floor?.level_label ?? '',
        object_id: unit.object_id,
      };
    }),
  );
};
