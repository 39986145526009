import React, { FC } from 'react';
import { getShortFloorLabel } from '../../utils/helpers';

interface TenantIndexProps {
  indices: string[];
  onClick: (index: string) => void;
  className?: string;
  mainRef?: React.MutableRefObject<HTMLDivElement | null>;
}

const TenantIndex: FC<TenantIndexProps> = ({
  indices,
  onClick,
  className,
  mainRef,
}) => {
  return (
    <div
      ref={mainRef}
      className={`flex flex-col justify-items-stretch overflow-auto ${
        className ?? ''
      }`}
    >
      {indices.map((index) => (
        <button
          key={index}
          className="h-[50px] min-h-[60px] w-[100px] rounded text-xl active:bg-gray-100"
          onClick={() => onClick(index)}
        >
          {getShortFloorLabel(index)}
        </button>
      ))}
    </div>
  );
};

export default TenantIndex;
