import { DateTime } from 'luxon';
import { Weather } from '../../hooks/weather/weather';

const today = DateTime.now().startOf('day').toISODate() as string;

export const ddWeatherForecast: Weather[] = [
  {
    symbol: 'Clear sky',
    temperature: 23,
    time: DateTime.now(),
    windDirection: 180,
    windSpeed: 1,
  },
  {
    symbol: 'Halfclear sky',
    temperature: 14,
    time: DateTime.now(),
    windDirection: 234,
    windSpeed: 5,
  },
  {
    symbol: 'Overcast',
    temperature: 14,
    time: DateTime.now(),
    windDirection: 123,
    windSpeed: 3,
  },
  {
    symbol: 'Fog',
    temperature: 11,
    time: DateTime.now(),
    windDirection: 195,
    windSpeed: 1,
  },
  {
    symbol: 'Light rain showers',
    temperature: 6,
    time: DateTime.now(),
    windDirection: 76,
    windSpeed: 2,
  },
  {
    symbol: 'Moderate snowfall',
    temperature: -1,
    time: DateTime.now(),
    windDirection: 32,
    windSpeed: 4,
  },
  {
    symbol: 'Thunder',
    temperature: 17,
    time: DateTime.now(),
    windDirection: 293,
    windSpeed: 6,
  },
];

export const ddWeather = {
  approvedTime: `${today}T12:06:19Z`,
  referenceTime: `${today}T12:00:00Z`,
  geometry: { type: 'Point', coordinates: [[15.582938, 56.199568]] },
  timeSeries: [
    {
      validTime: `${today}T13:00:00Z`,
      parameters: [
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [3],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.2],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.2],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.3],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.2],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [6],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [6],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [3.2] },
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1032.3],
        },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [10.0] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [71],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [2.2] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [91] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [4.4],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [18],
        },
      ],
    },
    {
      validTime: `${today}T14:00:00Z`,
      parameters: [
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [3],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.1],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.2],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.3],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.2],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [6],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [7],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [3.0] },
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1032.3],
        },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [9.6] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [56],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [1.9] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [93] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [4.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [18],
        },
      ],
    },
    {
      validTime: `${today}T15:00:00Z`,
      parameters: [
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [3],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.1],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.2],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.1],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [5],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [7],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [2.8] },
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1032.2],
        },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [5.8] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [35],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [1.8] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [95] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [3.5],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: `${today}T16:00:00Z`,
      parameters: [
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [3],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.1],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [5],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [2.6] },
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1032.1],
        },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [3.4] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [14],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [1.7] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [97] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [3.2],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: `${today}T17:00:00Z`,
      parameters: [
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [-9],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [0],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [6],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [2.3] },
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1032.2],
        },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [3.0] },
        { name: 'wd', levelType: 'hl', level: 10, unit: 'degree', values: [4] },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [2.0] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [97] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [3.6],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: `${today}T18:00:00Z`,
      parameters: [
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [-9],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [0],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [6],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [7],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [2.2] },
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1032.2],
        },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [3.6] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [348],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [1.8] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [96] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [3.6],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: `${today}T19:00:00Z`,
      parameters: [
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [2.3] },
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1031.9],
        },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [3.1] },
        { name: 'wd', levelType: 'hl', level: 10, unit: 'degree', values: [7] },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [2.5] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [97] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [7],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [6],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [4.6],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.1],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [4],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: `${today}T20:00:00Z`,
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1031.6],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [2.4] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [3.0] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [15],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [2.5] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [97] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [7],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [5],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [4.8],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.2],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [4],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: `${today}T21:00:00Z`,
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1031.6],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [2.7] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [3.4] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [14],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [2.6] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [96] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [7],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [6],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [4.8],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.4],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [4],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.1],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.1],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [18],
        },
      ],
    },
    {
      validTime: `${today}T22:00:00Z`,
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1031.6],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [3.0] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [3.6] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [23],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [3.0] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [96] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [7],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [7],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [5.6],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.5],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [4],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.2],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.2],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [18],
        },
      ],
    },
    {
      validTime: `${today}T23:00:00Z`,
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1031.6],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [3.3] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [3.5] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [25],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [2.9] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [96] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [7],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [5.6],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.1],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.7],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [4],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.3],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.2],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [18],
        },
      ],
    },
    {
      validTime: '2022-12-02T00:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1031.5],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [3.3] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [3.0] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [27],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [2.8] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [96] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [7],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [5.4],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.1],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.7],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [4],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.3],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.2],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [18],
        },
      ],
    },
    {
      validTime: '2022-12-02T01:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1031.4],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [3.3] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [4.0] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [29],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [2.8] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [96] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [6],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [5.2],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.1],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.6],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [4],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.3],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.2],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [18],
        },
      ],
    },
    {
      validTime: '2022-12-02T02:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1031.2],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [3.2] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [5.1] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [31],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [3.1] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [95] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [7],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [5],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [5.6],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.4],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [4],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.2],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.1],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [18],
        },
      ],
    },
    {
      validTime: '2022-12-02T03:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1031.0],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [3.0] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [5.4] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [25],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [3.0] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [95] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [6],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [5],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [5.5],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.2],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [4],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.1],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: '2022-12-02T04:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1030.9],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [2.9] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [5.9] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [21],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [3.1] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [95] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [5],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [5],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [5.6],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.1],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [4],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: '2022-12-02T05:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1030.8],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [3.0] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [7.8] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [24],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [3.3] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [94] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [3],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [4],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [6.0],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.1],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [4],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: '2022-12-02T06:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1030.8],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [2.8] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [11.0] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [28],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [3.2] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [94] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [1],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [4],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [6.0],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.2],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [4],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: '2022-12-02T07:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1031.1],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [2.7] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [5.8] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [22],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [2.9] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [95] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [1],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [4],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [5.7],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.3],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [4],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.1],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: '2022-12-02T08:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1031.4],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [2.8] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [6.3] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [25],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [2.7] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [95] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [3],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [5.5],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.3],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [4],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.1],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: '2022-12-02T09:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1031.6],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [3.2] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [8.5] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [29],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [2.7] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [94] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [1],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [1],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [5.1],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.2],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [4],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.1],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: '2022-12-02T10:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1031.6],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [3.6] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [9.9] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [40],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [3.7] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [92] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [1],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [6.8],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.1],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [3],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: '2022-12-02T11:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1031.5],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [3.8] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [14.1] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [39],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [4.0] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [87] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [1],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [1],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [7.4],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [-9],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [0],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: '2022-12-02T12:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1031.5],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [3.8] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [16.8] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [43],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [4.2] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [83] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [7],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [1],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [3],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [7.8],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [-9],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [0],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [4],
        },
      ],
    },
    {
      validTime: '2022-12-02T13:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1031.6],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [3.5] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [17.0] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [38],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [3.4] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [83] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [1],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [3],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [7.7],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [-9],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [0],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: '2022-12-02T14:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1031.7],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [3.1] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [15.0] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [29],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [2.6] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [85] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [2],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [6.2],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [-9],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [0],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: '2022-12-02T15:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1031.8],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [2.7] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [12.7] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [20],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [2.5] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [89] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [4.7],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [-9],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [0],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: '2022-12-02T16:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1031.9],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [2.5] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [10.7] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [26],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [2.6] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [91] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [4.8],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [-9],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [0],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: '2022-12-02T17:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1032.1],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [2.4] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [8.8] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [28],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [2.4] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [94] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [4.7],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [-9],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [0],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: '2022-12-02T18:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1032.2],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [2.2] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [8.8] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [26],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [2.6] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [93] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [4.8],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [-9],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [0],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: '2022-12-02T19:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1032.2],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [1.8] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [10.0] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [22],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [2.5] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [92] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [4.9],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [-9],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [0],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: '2022-12-02T20:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1032.4],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [1.4] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [10.5] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [22],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [2.2] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [91] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [4.5],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [-9],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [0],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: '2022-12-02T21:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1032.5],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [1.2] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [9.8] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [16],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [2.1] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [92] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [4.0],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [-9],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [0],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: '2022-12-02T22:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1032.6],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [1.5] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [59.8] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [19],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [2.6] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [93] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [4.7],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [-9],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [0],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: '2022-12-02T23:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1032.5],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [1.6] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [9.1] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [18],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [2.6] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [93] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [4.9],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [-9],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [0],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: '2022-12-03T00:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1032.3],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [1.5] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [59.6] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [18],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [2.9] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [93] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [5.2],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [-9],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [0],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: '2022-12-03T01:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1032.2],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [1.2] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [9.4] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [12],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [3.0] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [93] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [5.4],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [-9],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [0],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: '2022-12-03T02:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1031.9],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [0.7] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [7.9] },
        { name: 'wd', levelType: 'hl', level: 10, unit: 'degree', values: [9] },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [3.3] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [94] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [1],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [5.9],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [-9],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [0],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: '2022-12-03T03:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1031.6],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [0.6] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [9.2] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [19],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [3.1] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [93] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [1],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [5.9],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.1],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [28],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [4],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: '2022-12-03T04:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1031.3],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [0.8] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [8.9] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [24],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [3.1] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [93] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [1],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [5.9],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.1],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [28],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [4],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: '2022-12-03T05:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1031.1],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [1.0] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [75.0] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [26],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [3.2] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [93] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [2],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [6.3],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.1],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [28],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [4],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: '2022-12-03T06:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1030.9],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [1.0] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [9.8] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [23],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [3.2] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [92] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [4],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [6.0],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.1],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [18],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [4],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: '2022-12-03T07:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1030.9],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [0.9] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [11.0] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [27],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [3.4] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [91] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [6],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [6.1],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [-9],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [0],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: '2022-12-03T08:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1031.0],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [1.0] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [7.9] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [33],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [3.3] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [91] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [7],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [6.3],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.1],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [38],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [2],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: '2022-12-03T09:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1031.0],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [1.1] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [43.2] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [30],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [3.4] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [91] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [7],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [6.3],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.1],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [38],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [2],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: '2022-12-03T10:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1030.7],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [1.4] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [12.6] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [28],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [3.9] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [89] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [7],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [7.2],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.1],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [38],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [2],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: '2022-12-03T11:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1030.1],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [1.7] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [15.1] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [33],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [4.3] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [86] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [7],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [1],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [7],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [8.0],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.1],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [48],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [2],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: '2022-12-03T12:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1029.8],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [1.9] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [15.9] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [33],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [4.2] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [84] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [7],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [1],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [8.1],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [-9],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [0],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: '2022-12-03T13:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1029.5],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [1.8] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [16.4] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [32],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [4.2] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [84] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [7],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [2],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [7.8],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [-9],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [0],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: '2022-12-03T14:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1029.2],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [1.6] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [16.1] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [28],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [4.0] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [84] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [7],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [2],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [7],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [7.7],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [-9],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [0],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: '2022-12-03T15:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1028.9],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [1.2] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [15.9] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [28],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [3.8] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [84] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [7],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [2],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [6],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [7.4],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [-9],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [0],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: '2022-12-03T16:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1028.7],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [1.1] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [16.3] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [28],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [3.8] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [84] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [6],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [2],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [6],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [7.0],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [-9],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [0],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: '2022-12-03T17:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1028.4],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [1.0] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [16.6] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [29],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [4.3] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [84] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [6],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [2],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [6],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [7.9],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [-9],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [0],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: '2022-12-03T18:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1028.4],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [1.0] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [17.3] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [31],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [4.0] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [83] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [6],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [4],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [6],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [7.9],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [-9],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [0],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: '2022-12-03T19:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1028.3],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [1.0] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [18.5] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [28],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [4.0] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [81] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [7],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [5],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [5],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [7.4],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [-9],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [0],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: '2022-12-03T20:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1028.3],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [1.1] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [20.1] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [34],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [4.3] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [79] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [7],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [4],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [7.9],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.4],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [100],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [1],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.1],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: '2022-12-03T21:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1028.4],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [1.1] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [8.1] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [27],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [4.3] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [80] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [4],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [7.9],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.4],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [100],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [1],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.1],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: '2022-12-03T22:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1028.4],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [0.9] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [5.4] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [28],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [3.9] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [84] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [4],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [8.0],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.4],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [100],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [1],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.1],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.1],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [25],
        },
      ],
    },
    {
      validTime: '2022-12-03T23:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1028.3],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [0.9] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [4.7] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [27],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [3.5] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [88] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [4],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [7.1],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.2],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [92],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [1],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.1],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.1],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [25],
        },
      ],
    },
    {
      validTime: '2022-12-04T00:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1028.2],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [0.8] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [6.5] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [28],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [3.3] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [93] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [3],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [6.4],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.2],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [59],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [2],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.1],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.1],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [22],
        },
      ],
    },
    {
      validTime: '2022-12-04T06:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1028.1],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [1.0] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [49.2] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [62],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [4.5] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [90] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [6],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [8.6],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [-9],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [0],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: '2022-12-04T12:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1028.3],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [2.7] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [15.8] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [60],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [4.4] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [81] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [6],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [8.9],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [-9],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [0],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: '2022-12-04T18:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1028.0],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [2.8] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [16.7] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [72],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [4.9] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [78] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [9.4],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [-9],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [0],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: '2022-12-05T00:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1028.2],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [2.5] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [15.4] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [75],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [5.2] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [81] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [4],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [10.1],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [-9],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [0],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: '2022-12-05T06:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1027.4],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [3.7] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [50.0] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [79],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [5.5] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [84] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [7],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [10.6],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [-9],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [0],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: '2022-12-05T12:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1027.0],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [4.9] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [50.0] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [87],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [5.9] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [83] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [2],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [11.6],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [-9],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [0],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: '2022-12-05T18:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1025.7],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [3.9] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [50.0] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [89],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [5.0] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [85] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [9.7],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [-9],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [0],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: '2022-12-06T00:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1023.2],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [3.3] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [50.0] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [83],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [3.5] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [86] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [7.8],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.1],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [4],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: '2022-12-06T06:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1019.4],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [2.4] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [50.0] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [54],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [2.7] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [89] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [5.9],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.1],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [4],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: '2022-12-06T12:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1015.9],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [2.2] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [50.0] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [50],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [2.5] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [85] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [6.3],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [-9],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [0],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: '2022-12-06T18:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1011.4],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [0.5] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [45.5] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [57],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [1.1] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [91] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [3.9],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [-9],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [0],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: '2022-12-07T00:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1006.9],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [1.5] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [4.7] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [121],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [1.2] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [87] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [7],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [2.9],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.1],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [44],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [2],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
    {
      validTime: '2022-12-07T06:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1003.0],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [1.8] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [2.4] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [298],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [1.8] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [95] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [4.6],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.1],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.2],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [19],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [3],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.1],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.1],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [19],
        },
      ],
    },
    {
      validTime: '2022-12-07T12:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1001.3],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [2.4] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [7.6] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [260],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [1.4] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [90] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [3],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [2],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [3.3],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.1],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.3],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [38],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [2],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.2],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.2],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [13],
        },
      ],
    },
    {
      validTime: '2022-12-08T00:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [998.3],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [0.7] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [1.7] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [70],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [1.2] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [99] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [6],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [2.9],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.2],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [3],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.1],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.1],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [19],
        },
      ],
    },
    {
      validTime: '2022-12-08T12:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [997.7],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [0.8] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [2.4] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [30],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [4.3] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [91] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [3],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [7],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [9.1],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.1],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.3],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [88],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [1],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.2],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.2],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [26],
        },
      ],
    },
    {
      validTime: '2022-12-09T00:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1007.9],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [-1.2] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [32.8] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [280],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [4.6] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [83] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [2],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [2],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [8.9],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.9],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [3],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.1],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [4],
        },
      ],
    },
    {
      validTime: '2022-12-09T12:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1008.9],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [0.1] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [38.8] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [282],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [4.3] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [75] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [6],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [1],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [8.7],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [1.2],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [3],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.1],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [4],
        },
      ],
    },
    {
      validTime: '2022-12-10T00:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1009.6],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [-0.9] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [31.8] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [282],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [4.4] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [84] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [3],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [2],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [0],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [8.4],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.4],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [100],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [1],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [4],
        },
      ],
    },
    {
      validTime: '2022-12-10T12:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1009.0],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [0.6] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [30.7] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [358],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [4.5] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [78] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [4],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [4],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [1],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [8.8],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.7],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [100],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [1],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.1],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [26],
        },
      ],
    },
    {
      validTime: '2022-12-11T00:00:00Z',
      parameters: [
        {
          name: 'msl',
          levelType: 'hmsl',
          level: 0,
          unit: 'hPa',
          values: [1009.4],
        },
        { name: 't', levelType: 'hl', level: 2, unit: 'Cel', values: [-1.1] },
        { name: 'vis', levelType: 'hl', level: 2, unit: 'km', values: [29.3] },
        {
          name: 'wd',
          levelType: 'hl',
          level: 10,
          unit: 'degree',
          values: [348],
        },
        { name: 'ws', levelType: 'hl', level: 10, unit: 'm/s', values: [4.8] },
        { name: 'r', levelType: 'hl', level: 2, unit: 'percent', values: [86] },
        {
          name: 'tstm',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [0],
        },
        {
          name: 'tcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [8],
        },
        {
          name: 'lcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [6],
        },
        {
          name: 'mcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [3],
        },
        {
          name: 'hcc_mean',
          levelType: 'hl',
          level: 0,
          unit: 'octas',
          values: [1],
        },
        {
          name: 'gust',
          levelType: 'hl',
          level: 10,
          unit: 'm/s',
          values: [8.6],
        },
        {
          name: 'pmin',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'pmax',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [1.0],
        },
        {
          name: 'spp',
          levelType: 'hl',
          level: 0,
          unit: 'percent',
          values: [100],
        },
        {
          name: 'pcat',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [1],
        },
        {
          name: 'pmean',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.1],
        },
        {
          name: 'pmedian',
          levelType: 'hl',
          level: 0,
          unit: 'kg/m2/h',
          values: [0.0],
        },
        {
          name: 'Wsymb2',
          levelType: 'hl',
          level: 0,
          unit: 'category',
          values: [6],
        },
      ],
    },
  ],
};
