import { IconMultiplePages } from '@allbin/icons';
import React from 'react';

interface Props {
  icon: React.ReactNode;
  title: string | React.ReactNode;
  content?: React.ReactNode;
  slim?: boolean;
}

export const PageHeader: React.FC<Props> = ({ icon, title, content, slim }) => {
  return (
    <header
      className={`mb-1 flex ${
        slim ? 'h-[90px] min-h-[90px]' : 'h-[140px] min-h-[140px]'
      } w-full items-center gap-8 px-12`}
    >
      <div className="h-[40px] w-[40px]">{icon ?? <IconMultiplePages />}</div>
      <h2 className="text-[48px]">{title}</h2>
      {content && <aside className="grow">{content}</aside>}
    </header>
  );
};
