import { RequestHandler, rest } from 'msw';
import { ddWeather } from '../gallery/dummydata/ddWeather';

export const handlersSmhi: RequestHandler[] = [
  /**
   * @see https://opendata.smhi.se/apidocs/metfcst/index.html
   */
  rest.get(
    `https://opendata-download-metfcst.smhi.se/api/category/pmp3g/version/2/geotype/point/lon/:lon/lat/:lat/data.json`,
    (req, res, ctx) => res(ctx.json(ddWeather)),
  ),
];
