import React, { FC, useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { EmbedIcon } from '../components/EmbedIcon';
import { PageHeader } from '../components/PageHeader';
import { useEmbeddedUrls } from '../hooks/useEmbeddedUrls';
import { useInteractionTimeout } from '../hooks/useInteractionTimeout';
import { useConfig } from '../providers/ConfigProvider';
import { useNavigateToDefaultView } from '../hooks/useNavigateToDefaultView';

interface Props {
  portrait?: boolean;
}
const EmbeddedUrlView: FC<Props> = ({ portrait }) => {
  const { embeddedUrlId } = useParams();
  const { data, isLoading } = useEmbeddedUrls();
  const embeddedUrl = useMemo(
    () => data?.find((embed) => embed.id === embeddedUrlId),
    [data, embeddedUrlId],
  );
  const { embeddedUrlTimeout, defaultStartView } = useConfig();
  const navigateToDefaultView = useNavigateToDefaultView();

  const [temporarilyHide, setTimporarilyHide] = useState(false);

  const onInteractionTimeout = useCallback(() => {
    if (
      !embeddedUrlId ||
      defaultStartView !== `embedded-urls/${embeddedUrlId}`
    ) {
      navigateToDefaultView();
      return;
    }

    setTimporarilyHide(true);
    setTimeout(() => {
      setTimporarilyHide(false);
    }, 1);
  }, [defaultStartView, embeddedUrlId, navigateToDefaultView]);

  useInteractionTimeout(embeddedUrlTimeout, onInteractionTimeout);

  if (isLoading) {
    return null;
  }

  if (!embeddedUrl) {
    return null;
  }

  return (
    <div className="flex h-full min-w-0 flex-grow flex-col overflow-auto pb-12">
      <PageHeader
        icon={<EmbedIcon icon={embeddedUrl.icon} />}
        title={embeddedUrl.label}
        slim={portrait}
      />
      {!temporarilyHide ? (
        <iframe
          src={embeddedUrl.url}
          className="inset-0 mx-12 flex h-full items-center justify-center border"
          sandbox="allow-forms allow-scripts allow-same-origin"
        >
          <FormattedMessage defaultMessage="Kunde inte ladda sidan" />
        </iframe>
      ) : null}
    </div>
  );
};

export default EmbeddedUrlView;
