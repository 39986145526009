import { ApiAnnouncement } from '@allbin/viu-api-client';
import { DateTime } from 'luxon';
import { Metadata, reviveMeta } from './metadata';

export const reviveAnnouncement = (
  announcement: ApiAnnouncement,
): Announcement => ({
  ...announcement,
  meta: reviveMeta(announcement.meta),
  active_from: announcement.active_from
    ? DateTime.fromISO(announcement.active_from)
    : undefined,
  active_to: announcement.active_to
    ? DateTime.fromISO(announcement.active_to)
    : undefined,
});

export interface Announcement
  extends Omit<ApiAnnouncement, 'active_from' | 'active_to' | 'meta'> {
  active_from?: DateTime;
  active_to?: DateTime;
  meta: Metadata;
}
