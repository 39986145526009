import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useApiClient } from './useApiClient';
import { useIdentity } from '../providers/IdentityProvider';
import { Location, reviveLocation } from '../types/location';

export const useLocationInternal = (): UseQueryResult<Location> => {
  const apiClient = useApiClient();
  const { id } = useIdentity();

  return useQuery({
    queryKey: ['devices', id, 'location'],
    queryFn: () =>
      apiClient.public.devices
        .getLocation(id ?? '')
        .then((location) => reviveLocation(location)),
    enabled: !!id,
  });
};
