import { ApiEmbeddedUrl } from '@allbin/viu-api-client';
import { DateTime } from 'luxon';
import { Metadata, reviveMeta } from './metadata';

export const reviveEmbeddedUrl = (url: ApiEmbeddedUrl): EmbeddedUrl => {
  return {
    ...url,
    meta: reviveMeta(url.meta),
    active_from: url.active_from
      ? DateTime.fromISO(url.active_from)
      : undefined,
    active_to: url.active_to ? DateTime.fromISO(url.active_to) : undefined,
  };
};

export interface EmbeddedUrl
  extends Omit<ApiEmbeddedUrl, 'active_from' | 'active_to' | 'meta'> {
  active_from?: DateTime;
  active_to?: DateTime;
  meta: Metadata;
}
