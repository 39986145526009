import { Floor, reviveFloor } from '../types/floor';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useApiClient } from './useApiClient';
import { useIdentity } from '../providers/IdentityProvider';

export const useFloors = (): UseQueryResult<Floor[]> => {
  const apiClient = useApiClient();
  const { id } = useIdentity();
  const query = useQuery({
    queryKey: ['floors', id],
    queryFn: async ({ queryKey: [, id] }) =>
      await apiClient.public.devices
        .getFloors(id ?? '')
        .then((floors) => floors.map((floor) => reviveFloor(floor))),
    enabled: !!id,
  });

  return query;
};
