import { RequestHandler, rest } from 'msw';
import { ddDepartures, ddStops } from '../gallery/dummydata/ddPublicTransit';

export const handlersGtfs: RequestHandler[] = [
  /**
   * GTFS data for departures
   */
  rest.get('https://v3.gtfs-query.allbin.se/api/v2/stops', (req, res, ctx) => {
    return res(ctx.json(ddStops));
  }),
  rest.get(
    'https://v3.gtfs-query.allbin.se/api/v2/departures',
    (req, res, ctx) => {
      return res(ctx.json(ddDepartures));
    },
  ),
];
