import * as Popover from '@radix-ui/react-popover';
import React, { useEffect, useState } from 'react';
import { useEnv } from '../../hooks/useEnv';
import { useVersion } from '../../hooks/useVersion';
import ViuLogo from '../../icons/ViuLogo';
import { useIdentity } from '../../providers/IdentityProvider';
import { cn } from '../../utils/classNames';
import Button from '../Button';
import { FormattedMessage } from 'react-intl';
import { useFontSize } from '../../hooks/useFontSize';

const POPOVER_CLOSE_TIMER = 10000;

let popoverCloseTimer: number | null = null;

export const Footer: React.FC = () => {
  const { version } = useVersion();
  const { api } = useEnv();

  const { id } = useIdentity();
  const [infoOpen, setInfoOpen] = useState(false);
  const { fontSize, setFontSize } = useFontSize();

  useEffect(() => {
    if (!infoOpen && popoverCloseTimer !== null) {
      window.clearTimeout(popoverCloseTimer);
      return;
    }

    popoverCloseTimer = window.setTimeout(() => {
      setInfoOpen(false);
    }, POPOVER_CLOSE_TIMER);

    return () => {
      if (popoverCloseTimer !== null) {
        window.clearTimeout(popoverCloseTimer);
      }
    };
  }, [infoOpen]);

  return (
    <div className="flex items-center px-4">
      <Popover.Root open={infoOpen} onOpenChange={setInfoOpen}>
        <Popover.Trigger>
          <ViuLogo className="h-[20px] w-[20px] opacity-50" />
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content
            className={cn(
              'ml-[16px] flex w-[400px] flex-col gap-[16px] rounded-[4px] border border-gray-light bg-white p-[20px] shadow-xl',
            )}
            sideOffset={5}
          >
            <div className="grid grid-cols-[100px_auto] gap-[8px] break-all text-[16px]">
              <span>ID</span>
              <span>{id}</span>
              <span>Web Version:</span>
              <span>{version}</span>
              <span>API:</span>
              <span>{api?.replace('https://', '')}</span>
            </div>
            <Button
              label={<FormattedMessage defaultMessage="Ladda om" />}
              onClick={() => window.location.reload()}
              className="min-h-[48px] gap-[4px] rounded-[4px] px-[24px] text-[16px]"
            />
            <div>
              <p className="text-[16px]">Textstorlek:</p>
              <div className="flex gap-[4px]">
                {FONT_SIZES.map(({ label, size }) => (
                  <Button
                    key={label}
                    label={label}
                    className={`min-h-[48px] w-full gap-[4px] rounded-[4px] px-[24px] text-[16px] ${
                      size === fontSize ? 'bg-green' : ''
                    }`}
                    onClick={() => setFontSize(size)}
                  />
                ))}
              </div>
            </div>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
      <div className="flex-1" />
    </div>
  );
};

const FONT_SIZES = [
  { label: 'Normal', size: '16px' },
  { label: 'Stor', size: '22px' },
];

export default Footer;
