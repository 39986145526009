import React, { FC, ReactNode } from 'react';
import { Dialog } from '@headlessui/react';
import { cn } from '../utils/classNames';

interface Props {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  className?: string;
}
export const Modal: FC<Props> = ({ open, onClose, children, className }) => {
  return (
    <Dialog
      as="div"
      open={open}
      onClose={() => {
        // DO NOTHING
      }}
      className="relative z-50"
    >
      <div
        className="pointer-events-auto fixed inset-0 bg-gray-dark/30 backdrop-blur-sm"
        aria-hidden="true"
      />

      <div
        className="fixed inset-0 w-screen overflow-y-auto p-10"
        onClick={onClose}
      >
        <div className="flex min-h-full items-center justify-center">
          <Dialog.Panel
            className={cn(
              'flex max-w-[800px] rounded-lg bg-black/60 backdrop-blur-lg p-12',
              className,
            )}
          >
            {children}
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
};
