import { useMemo } from 'react';
import { useFeatures } from './useFeatures';
import { useServices } from './useServices';
import { Service } from '../types/service';
import { useUnits } from './useUnits';

export const useUnitsServices = (): Record<string, Service[]> => {
  const { data: units } = useUnits();
  const { data: allServices } = useServices();
  const { data: features } = useFeatures();

  const services = useMemo(() => {
    if (!features || !allServices || !units) {
      return {};
    }

    const result: Record<string, Service[]> = {};

    units.forEach((unit) => {
      const ids = features.features.find(
        (f) => f.properties?.unit_id === unit.id,
      )?.properties?.service_ids;
      const unitServices = allServices
        .filter((s) => ids?.includes(s.id))
        .sort((a, b) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
        );
      result[unit.id] = unitServices;
    });

    return result;
  }, [allServices, features, units]);

  return services;
};
