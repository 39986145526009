import { useCallback, useEffect, useState } from 'react';

export const useIsOffline = (): boolean => {
  const [isOffline, setIsOffline] = useState(!window.navigator.onLine);
  const setOnline = useCallback(() => setIsOffline(false), []);
  const setOffline = useCallback(() => setIsOffline(true), []);

  useEffect(() => {
    window.addEventListener('online', setOnline);
    window.addEventListener('offline', setOffline);
    return () => {
      window.removeEventListener('online', setOnline);
      window.removeEventListener('offline', setOffline);
    };
  }, [setOffline, setOnline]);

  return isOffline;
};
