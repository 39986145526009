import React, { FC, useCallback, useMemo, useState } from 'react';
import { useNews } from '../../hooks/useNews';
import { prettifyDate } from '../../utils/helpers';
import { NewsArticle } from '../../types/news';
import { NewsArticleModal } from './NewsArticleModal';
import { useInteractionTimeout } from '../../hooks/useInteractionTimeout';
import { useFontSize } from '../../hooks/useFontSize';

const ARTICLE_WIDTH = 560;

export const NewsFeed: FC = () => {
  const { data: news } = useNews();
  const [selectedArticle, setSelectedArticle] = useState<
    NewsArticle | undefined
  >(undefined);
  const { fontSize } = useFontSize();

  const shownArticles = useMemo(() => {
    const list = news?.slice(0, 3) ?? [];
    const threeMonthsBack = new Date();
    threeMonthsBack.setMonth(threeMonthsBack.getMonth() - 3);
    for (const article of news?.slice(3) ?? []) {
      if (new Date(article.published) > threeMonthsBack) {
        list.push(article);
      } else {
        break;
      }
    }
    return list;
  }, [news]);

  const onInterval = useCallback(() => {
    if (shownArticles.length === 0) {
      return;
    }

    const el = document.getElementById('newsFeed');
    if (!el || selectedArticle) {
      return;
    }

    let newLeft = el.scrollLeft + ARTICLE_WIDTH;
    if (newLeft > (shownArticles.length - 1) * ARTICLE_WIDTH) {
      newLeft = 0;
    }
    el.scrollTo({
      left: newLeft,
      behavior: 'smooth',
    });
  }, [shownArticles, selectedArticle]);

  useInteractionTimeout(8000, onInterval, !selectedArticle);

  const textHeight = useMemo(() => {
    const newsItemHeight = 185;
    const padding = 16;
    const titleSize = 1.75;
    const dateSize = 1;
    const fontSizePx = parseInt(fontSize.replace('px', ''));
    const adjustedLineHeight = 1.25 * fontSizePx;

    return (
      Math.floor(
        (newsItemHeight - 2 * padding - fontSizePx * (titleSize + dateSize)) /
          adjustedLineHeight,
      ) * adjustedLineHeight
    );
  }, [fontSize]);

  if (shownArticles.length === 0) {
    return null;
  }

  return (
    <div
      id="newsFeed"
      className="flex h-full grow snap-x snap-mandatory gap-[32px] overflow-auto p-[24px]"
    >
      {shownArticles.map((article) => {
        const { id, published, image, title, text } = article;

        const date = new Date(published);

        return (
          <div
            key={id}
            className={`flex h-full snap-center shadow-[0_3px_15px_#999]`}
            style={{
              width: `${ARTICLE_WIDTH}px`,
              minWidth: `${ARTICLE_WIDTH}px`,
            }}
            onClick={() => setSelectedArticle(article)}
          >
            {image ? (
              <img
                alt="article"
                src={image}
                className="aspect-square h-full bg-gray-200 object-cover"
              />
            ) : null}
            <div className="flex flex-col overflow-hidden p-[16px]">
              <p className="truncate text-lg font-bold">{title}</p>
              <p className="text-gray-700">{prettifyDate(date)}</p>
              <p
                dangerouslySetInnerHTML={{ __html: text }}
                className="pointer-events-none truncate leading-5 [&>*]:whitespace-normal"
                style={{ maxHeight: `${textHeight}px` }}
              />
            </div>
          </div>
        );
      })}

      <NewsArticleModal
        newsArticle={selectedArticle}
        show={!!selectedArticle}
        onClose={() => setSelectedArticle(undefined)}
      />
    </div>
  );
};
