import React, { FC, useMemo, useCallback } from 'react';
import { IconMap } from '@allbin/icons';
import { Dialog } from '@headlessui/react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFeatures } from '../hooks/useFeatures';
import { useFloors } from '../hooks/useFloors';
import { useUnits } from '../hooks/useUnits';
import { Company } from '../types/company';
import { prettifyPhoneNumber } from '../utils/helpers';
import Button from './Button';
import { RenderQrCode } from './RenderQrCode';

interface Props {
  company: Company;
}
export const CompanyDetails: FC<Props> = ({ company }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { data: units } = useUnits();
  const { data: floors } = useFloors();
  const { data: feature_collection } = useFeatures();

  const { logo, name, description, contacts, url } = company;

  const unit = useMemo(() => {
    if (!units) {
      return undefined;
    }

    return units.find(({ tenants }) =>
      tenants.find((t) => {
        if (t.type !== 'company') {
          return;
        }

        return t.company.name === name;
      }),
    );
  }, [units, name]);

  const showMapButton = useMemo(() => {
    if (
      location.pathname.includes('floor-plan') ||
      !floors ||
      !feature_collection
    ) {
      return false;
    }

    return floors.some((floor) =>
      feature_collection.features.some(
        (f) => f.properties?.['floor_id'] === floor.id,
      ),
    );
  }, [location.pathname, floors, feature_collection]);

  const onClickToMap = useCallback(() => {
    if (!unit) {
      return;
    }
    navigate(`/floor-plan?unitId=${unit.id}`);
  }, [unit, navigate]);

  return (
    <div className="flex flex-col gap-8 ">
      <div className="flex gap-10 justify-between">
        <div className="flex flex-col justify-start gap-5">
          <Dialog.Title as="h3" className="text-4xl font-medium text-white">
            {name}
          </Dialog.Title>

          {description ? (
            <p className="whitespace-pre-wrap text-base text-gray-100">
              {description}
            </p>
          ) : null}
        </div>
        <div className="flex flex-col gap-5">
          {logo ? (
            <div className="flex size-36 min-w-36 rounded-md overflow-hidden">
              <img
                src={logo}
                alt="company logo"
                className="size-full object-contain"
              />
            </div>
          ) : null}
          {url ? (
            <div className="overflow-hidden size-36 flex justify-center items-center rounded-md">
              <RenderQrCode url={url} scale={4} />
            </div>
          ) : null}
        </div>
      </div>
      {contacts.length > 0 ? (
        <div className="flex flex-col gap-2">
          <p className="text-gray-100 font-bold">
            <FormattedMessage defaultMessage="Kontaktpersoner" />
          </p>
          <div className="grid grid-cols-[repeat(3,1fr)] gap-5 text-gray-200">
            {contacts.map(({ name, title, phone, email }) => (
              <div
                key={name}
                className="flex items-center p-6 gap-3 rounded-lg flex-col bg-white/10"
              >
                <span className="text-white leading-5 text-center">{name}</span>
                {title ? <i>{title}</i> : null}
                {phone ? <span>{prettifyPhoneNumber(phone)}</span> : null}
                {email ? (
                  <div className="flex flex-col items-center mt-auto">
                    <span className="text-sm text-gray-400">Email</span>
                    <RenderQrCode
                      url={`mailto:${email}`}
                      scale={3}
                      className="rounded-md"
                    />
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        </div>
      ) : null}
      {showMapButton ? (
        <div className="flex items-end">
          <Button
            className="ml-auto"
            startIcon={<IconMap />}
            label={
              <FormattedMessage defaultMessage="Hitta oss på planskissen" />
            }
            onClick={onClickToMap}
          />
        </div>
      ) : null}
    </div>
  );
};
