import { IViuApiClient, ViuApiClient } from '@allbin/viu-api-client';
import { useMemo } from 'react';
import { useIdentity } from '../providers/IdentityProvider';
import { useEnv } from './useEnv';

export const useApiClient = (): IViuApiClient => {
  const { apiKey } = useIdentity();
  const { api } = useEnv();

  return useMemo<IViuApiClient>(() => {
    return ViuApiClient({
      baseUrl: api || '',
      apiKey,
    });
  }, [apiKey, api]);
};
