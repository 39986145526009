import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { BookableResourceWithEvents } from '../types/bookableResource';
import { IconGroup } from '@allbin/icons';
import { EventsTimeline } from './EventsTimeline';
import { cn } from '../utils/classNames';
// import { RenderQrCode } from '../RenderQrCode';

export interface ResourceWithEventsAndCapacity
  extends BookableResourceWithEvents {
  capacity?: number;
}

interface Props {
  resource: ResourceWithEventsAndCapacity;
  hideName?: boolean;
  className?: string;
}
export const ResourceDetails: FC<Props> = ({
  resource,
  hideName,
  className,
}) => {
  const { name, events, capacity } = resource;

  return (
    <div
      key={resource.resource_source_id}
      className={cn('flex h-[24rem] min-h-[24rem]', className)}
    >
      <div className="flex flex-col">
        {!hideName ? <p className="text-lg font-bold">{name}</p> : null}
        {capacity ? (
          <p className="flex items-center gap-2 text-gray-600">
            <IconGroup className="size-5" /> {capacity}{' '}
            <FormattedMessage defaultMessage="platser" />
          </p>
        ) : null}
        <div className="w-[200px] mt-auto">
          {/* {url ? (
            <RenderQrCode
              url={url}
              scale={7}
              className="-ml-6"
            />
          ) : null} */}
        </div>
      </div>
      <div className="flex flex-col ml-auto">
        <p className="mb-3 text-lg">
          <FormattedMessage defaultMessage="Bokningar" />
        </p>
        <EventsTimeline events={events} />
      </div>
    </div>
  );
};
