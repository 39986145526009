import React, { FC, ReactNode } from 'react';
import { PartialState } from '../store/config';
import useConfig from '../hooks/useConfig';
import CoveringLoadingScreen from '../components/CoveringLoadingScreen';
import { FormattedMessage } from 'react-intl';
import { useQueryClient } from '@tanstack/react-query';
import { useQueryParams } from '../views/LoginView';
import { Navigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';

const ConfigProvider: FC<{ overrides?: PartialState; children: ReactNode }> = ({
  children,
}) => {
  const searchParams = useQueryParams();

  const { isFetched, isLoading, error } = useConfig();
  const queryClient = useQueryClient();

  if (searchParams.id) {
    const params = new URLSearchParams({
      ...searchParams,
    });

    const login = `/login?${params.toString()}`;
    return <Navigate to={login} replace={true} />;
  }

  if (isLoading) {
    return (
      <CoveringLoadingScreen
        message={<FormattedMessage defaultMessage="Laddar konfiguration..." />}
        progressBar={true}
      />
    );
  }

  if (error && !isFetched) {
    const eventId = Sentry.captureMessage(
      'Failed to load config from viu-api',
      {
        extra: {
          error,
        },
      },
    );

    return (
      <CoveringLoadingScreen
        message={
          <div className="flex flex-col gap-4 text-gray-900">
            <h2>
              <FormattedMessage defaultMessage="Ett fel uppstod vid uppstart, vänligen kontakta supporten" />
            </h2>
            <p className="text-center text-gray font-small">{eventId}</p>
          </div>
        }
        action={
          <button
            className="duration-50 flex flex-row rounded-md border-2 border-green-dark bg-green p-2 text-green-extraLight transition-colors ease-in-out active:border-green-dark active:bg-green-dark"
            onClick={() => {
              void queryClient.invalidateQueries();
            }}
          >
            Försök igen
          </button>
        }
      />
    );
  }

  return <>{children}</>;
};

export { ConfigProvider };
export { useConfig };
