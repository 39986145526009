import React, {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import { IntlProvider } from 'react-intl';
import { useConfig } from './ConfigProvider';
import { Settings } from 'luxon';

export type Locale = 'en-US' | 'sv-SE';
export const validLocales = ['en-US', 'sv-SE'];

export interface State {
  locale: Locale;
}

export type Action = { type: 'set'; locale: Locale };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'set': {
      return { ...state, locale: action.locale };
    }
    default: {
      console.error('Invalid dispatch action: ', action);
      return state;
    }
  }
};

const LocaleStateContext = createContext<State | undefined>(undefined);
const LocaleDispatchContext = createContext<Dispatch<Action> | undefined>(
  undefined,
);

const LocaleProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { locale: defaultLocale } = useConfig();

  const [state, dispatch] = useReducer(reducer, {
    locale: defaultLocale,
  });

  const messages = useMemo(
    () => ({
      'en-US': {},
      'sv-SE': {},
    }),
    [],
  );

  useEffect(() => {
    Settings.defaultLocale = state.locale;
  }, [state.locale]);

  return (
    <LocaleStateContext.Provider value={state}>
      <LocaleDispatchContext.Provider value={dispatch}>
        <IntlProvider
          messages={messages[state.locale]}
          locale={state.locale}
          defaultLocale={'sv-SE'}
        >
          {children}
        </IntlProvider>
      </LocaleDispatchContext.Provider>
    </LocaleStateContext.Provider>
  );
};

const useLocale = (): [State, Dispatch<Action>] => {
  const state = useContext(LocaleStateContext);
  const dispatch = useContext(LocaleDispatchContext);

  if (state === undefined || dispatch === undefined) {
    throw new Error('useLocale must be used within a LocaleProvider');
  }

  return [state, dispatch];
};

const setLocale = (locale: Locale, dispatch: Dispatch<Action>): void => {
  dispatch({ type: 'set', locale: locale });
  localStorage.setItem('locale', locale);
};

export { LocaleProvider, useLocale, setLocale };
