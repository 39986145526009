import { CompanyTenant } from '../types/unit';
import { getCountryCode } from './countryCodes';

export const sanitizePhoneNumber = (phoneNumber: string): string => {
  return phoneNumber.replace(/[^\d+]/g, '');
};

const formatPhoneNumber = (phoneNumber: string): string => {
  return [
    phoneNumber.substring(0, 3),
    phoneNumber.substring(3, 6),
    phoneNumber.substring(6),
  ].join(' ');
};

export const prettifyPhoneNumber = (phone: string): string => {
  const number = sanitizePhoneNumber(phone);

  const countryCode = getCountryCode(number);
  const countryCodeIndex = countryCode.length;
  const plainPhoneNumber = number.substring(countryCodeIndex);

  return countryCode
    ? countryCode + ' ' + formatPhoneNumber(plainPhoneNumber)
    : formatPhoneNumber(plainPhoneNumber);
};

export const sleep = (ms: number): Promise<void> =>
  new Promise((r) => setTimeout(r, ms));

const singleToDouble = (n: number): string => (n > 9 ? n.toString() : `0${n}`);

export const prettifyDate = (date: Date): string =>
  `${date.getFullYear()}-${singleToDouble(
    date.getMonth() + 1,
  )}-${singleToDouble(date.getDate())}`;

export const prettifyTime = (date: Date): string =>
  `${singleToDouble(date.getHours())}:${singleToDouble(date.getMinutes())}`;

export const getShortFloorLabel = (index: string): string => {
  const parts = index.split(' ');
  return parts[0];
};

const combineFirstLetters = (words: string[]): string => {
  return words.reduce((res, word) => {
    return res + word[0];
  }, '');
};
export const getCompanyShortName = (tenant: CompanyTenant): string => {
  const name = tenant.company.name;
  const parts = name.split(' ');
  if (parts.length > 1) {
    return combineFirstLetters(parts);
  }
  const matches = name.match(/[A-Z][a-z]+/g) || [];
  if (matches.length > 1) {
    return combineFirstLetters(matches);
  }
  return name.slice(0, 3);
};
