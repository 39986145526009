import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { EmbeddedUrl, reviveEmbeddedUrl } from '../types/embedded_url';
import { useApiClient } from './useApiClient';
import { useIdentity } from '../providers/IdentityProvider';

export const useEmbeddedUrls = (): UseQueryResult<EmbeddedUrl[]> => {
  const apiClient = useApiClient();
  const { id } = useIdentity();

  return useQuery({
    queryKey: ['devices', id, 'embedded_urls'],
    queryFn: ({ queryKey: [, id] }) =>
      apiClient.public.devices
        .getEmbeddedUrls(id || '')
        .then((embeddedUrls) =>
          embeddedUrls.map((embeddedUrl) => reviveEmbeddedUrl(embeddedUrl)),
        ),
    enabled: !!id,
  });
};
