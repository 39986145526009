import { ApiMetadata } from '@allbin/viu-api-client';
import { DateTime } from 'luxon';

export interface Metadata {
  created_at: DateTime;
  created_by: string;
  updated_at: DateTime;
}

export const reviveMeta = (meta: ApiMetadata): Metadata => ({
  created_at: DateTime.fromISO(meta.created_at),
  created_by: meta.created_by,
  updated_at: DateTime.fromISO(meta.updated_at),
});

export const serializeMeta = (meta: Metadata): ApiMetadata => ({
  created_at: meta.created_at.toISO() as string,
  created_by: meta.created_by,
  updated_at: meta.updated_at.toISO() as string,
});
