import React, { FC, useEffect, useRef } from 'react';
import QRCode from 'qrcode';

interface Props {
  url: string;
  scale?: number;
  className?: string;
}
export const RenderQrCode: FC<Props> = ({ url, scale = 10, className }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    QRCode.toCanvas(
      canvasRef.current,
      url,
      { errorCorrectionLevel: 'low', scale },
      (err) => {
        if (err) {
          throw err;
        }
      },
    );
  }, [scale, url]);

  return <canvas ref={canvasRef} className={className} />;
};
