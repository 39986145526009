import { useEffect } from 'react';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface UseFontSize {
  fontSize: string;
  setFontSize: (fontSize: string) => void;
}

const useFontSizeStore = create<UseFontSize>()(
  persist(
    (set) => ({
      fontSize: '16px',
      setFontSize: (fontSize: string) => set({ fontSize }),
    }),
    { name: 'scaling' },
  ),
);

export const useFontSize = (): UseFontSize => {
  const { fontSize, setFontSize } = useFontSizeStore();

  useEffect(() => {
    const html = document.getElementsByTagName('html').item(0);
    if (!html) {
      return;
    }
    html.style.fontSize = fontSize;
  }, [fontSize]);

  return { fontSize, setFontSize };
};
