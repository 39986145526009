import React, { ReactNode, useMemo } from 'react';
import { getTenantsWithFloor } from '../utils/TenantUtils';
import { useUnits } from './useUnits';
import { useFloors } from './useFloors';
import { IconHomeUser, IconPasteClipboard } from '@allbin/icons';
import { FormattedMessage } from 'react-intl';
import { Demographic, TenantWithFloor } from '../types/unit';

interface UseTenants {
  tenants: TenantWithFloor[];
  demographic: Demographic;
  tenantLinkProps: {
    icon: ReactNode;
    label: ReactNode;
  };
}

export const useTenants = (): UseTenants => {
  const { data: units } = useUnits();
  const { data: floors } = useFloors();

  const tenants = useMemo(
    () => (units && floors ? getTenantsWithFloor(units, floors) : []),
    [units, floors],
  );

  const demographic = useMemo((): Demographic => {
    let companyCount = 0;
    let individualCount = 0;
    for (const tenant of tenants) {
      if (tenant.type === 'company') {
        companyCount += 1;
      } else {
        individualCount += 1;
      }
      if (companyCount > 0 && individualCount > 0) {
        return 'mixed';
      }
    }

    if (companyCount === 0) {
      return 'individuals';
    }
    return 'companies';
  }, [tenants]);

  const tenantLinkProps = useMemo(() => {
    switch (demographic) {
      case 'companies':
        return {
          icon: <IconPasteClipboard />,
          label: <FormattedMessage defaultMessage="Företag" />,
        };
      case 'individuals':
        return {
          icon: <IconHomeUser />,
          label: <FormattedMessage defaultMessage="Boende" />,
        };

      default:
        return {
          icon: <IconPasteClipboard />,
          label: <FormattedMessage defaultMessage="Hyresgäster" />,
        };
    }
  }, [demographic]);

  return { tenants, demographic, tenantLinkProps };
};
