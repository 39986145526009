import { reviveFloor } from '../../types/floor';
import { ApiFloor } from '@allbin/viu-api-client';

import floor1 from './kruthusen/CG8_floor1.png';
import floor2 from './kruthusen/CG8_floor2.png';
import { DateTime } from 'luxon';

export const ddApiFloors: ApiFloor[] = [
  {
    id: 'floor_0002',
    organization_id: 'allbinary',
    meta: {
      created_at: DateTime.now().toISO() as string,
      created_by: 'Mock user',
      updated_at: DateTime.now().toISO() as string,
    },
    level: 2,
    location_id: 'location_0001',
    floor_plan: floor2,
    level_label: '2',
  },
  {
    id: 'floor_0001',
    organization_id: 'allbinary',
    meta: {
      created_at: DateTime.now().toISO() as string,
      created_by: 'Mock user',
      updated_at: DateTime.now().toISO() as string,
    },
    level: 1,
    location_id: 'location_0001',
    floor_plan: floor1,
    level_label: 'Entrévåning',
  },
];

export const ddFloors = ddApiFloors.map((f) => reviveFloor(f));
