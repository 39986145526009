import React, { FC } from 'react';
import { Service } from '../../types/service';
import { Modal } from '../Modal';
import { ServiceDetails } from '../ServiceDetails';

interface Props {
  service?: Service;
  show: boolean;
  onClose: () => void;
}

export const ServiceModal: FC<Props> = ({ service, show, onClose }) => {
  if (!service) {
    return null;
  }

  return (
    <Modal open={show} onClose={onClose} className="flex w-4/5">
      <ServiceDetails service={service} />
    </Modal>
  );
};
