import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { PageHeader } from '../components/PageHeader';
import { FormattedMessage } from 'react-intl';
import { Attachment } from '../types/attachments';
import { AttachmentViewer } from '../components/Attachments/AttachmentViewer';
import { AttachmentsMenuItem } from '../components/Attachments/AttachmentsMenuItem';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUrlQuery } from '../hooks/useUrlQuery';
import Button from '../components/Button';
import { IconArrowLeft } from '@allbin/icons';
import { useInteractionTimeout } from '../hooks/useInteractionTimeout';
import { useNavigateToDefaultView } from '../hooks/useNavigateToDefaultView';
import { useConfig } from '../providers/ConfigProvider';

interface Props {
  icon?: React.ReactNode;
  title: string | React.ReactNode;
  attachments?: Attachment[];
  portrait?: boolean;
}

const AttachmentsView: FC<Props> = ({ icon, title, attachments, portrait }) => {
  const { interactionTimeout } = useConfig();
  const navigateToDefaultView = useNavigateToDefaultView();
  useInteractionTimeout(interactionTimeout, navigateToDefaultView);
  const query = useUrlQuery();
  const queryId = query.get('attachment');

  const queryAttachmentExists = useMemo(() => {
    return !!attachments?.find((att) => att.id === queryId);
  }, [attachments, queryId]);

  useEffect(() => {
    if (queryId && !queryAttachmentExists) {
      navigateToDefaultView();
    }
  }, [queryAttachmentExists, attachments, navigateToDefaultView, queryId]);

  if (!attachments) {
    return null;
  }

  return (
    <main className="relative flex grow flex-col overflow-hidden">
      <PageHeader icon={icon} title={title} slim={portrait} />
      <section className="flex grow overflow-hidden">
        {!attachments && (
          <p className="m-auto text-gray body1">
            <FormattedMessage defaultMessage="Det finns för närvarande inga dokument att visa" />
          </p>
        )}
        <AttachmentSelector attachments={attachments} portrait={portrait} />
      </section>
    </main>
  );
};

interface AttachmentSelectorProps {
  attachments: Attachment[];
  portrait?: boolean;
}
const AttachmentSelector: FC<AttachmentSelectorProps> = ({
  attachments,
  portrait,
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const query = useUrlQuery();
  const [selectedAttachment, setSelectedAttachment] = useState<
    Attachment | undefined
  >(undefined);

  useEffect(() => {
    if (selectedAttachment && query.size === 0) {
      setSelectedAttachment(undefined);
      return;
    }
    const queryId = query.get('attachment');
    if (queryId) {
      setSelectedAttachment(attachments.find((att) => att.id === queryId));
    }
  }, [attachments, query, selectedAttachment]);

  const onAttachmentClick = useCallback(
    (attachment: Attachment): void => {
      setSelectedAttachment(attachment);
      navigate(`${pathname}?attachment=${attachment.id}`);
    },
    [navigate, pathname],
  );

  const onBack = useCallback(() => {
    setSelectedAttachment(undefined);
    navigate(`${pathname}`);
  }, [navigate, pathname]);

  if (attachments.length === 1) {
    return <AttachmentViewer attachment={attachments[0]} />;
  }

  return (
    <div className="relative flex w-full flex-wrap content-start items-center justify-evenly gap-4 overflow-auto px-12 pb-12">
      {attachments.map((attachment) => (
        <AttachmentsMenuItem
          key={attachment.id}
          attachment={attachment}
          onClick={() => onAttachmentClick(attachment)}
        />
      ))}
      {selectedAttachment ? (
        <div className="absolute inset-0 bg-white">
          <AttachmentViewer attachment={selectedAttachment} />
          <Button
            className={`fixed !px-7 !py-4 !text-[18px] shadow-2xl ${
              portrait ? 'bottom-[570px] left-6' : 'bottom-[30px] right-5'
            }`}
            onClick={onBack}
          >
            <IconArrowLeft className="!h-8 !w-8" />
            <FormattedMessage defaultMessage="Tillbaka" />
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default AttachmentsView;
