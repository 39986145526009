import { ApiFloor, ApiFloorRequest } from '@allbin/viu-api-client';

import { Metadata, reviveMeta } from './metadata';

export interface Floor extends Omit<ApiFloor, 'meta'> {
  meta: Metadata;
}

export type FloorRequest = ApiFloorRequest;

export const reviveFloor = (floor: ApiFloor): Floor => ({
  ...floor,
  meta: reviveMeta(floor.meta),
});
