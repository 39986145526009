import { ApiAttachment, ApiAttachmentCategory } from '@allbin/viu-api-client';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useIdentity } from '../providers/IdentityProvider';
import { Attachment, reviveAttachment } from '../types/attachments';
import { useApiClient } from './useApiClient';
import { useEnv } from './useEnv';

export const useAttachments = (): UseQueryResult<
  Record<ApiAttachmentCategory, Attachment[]>
> => {
  const apiClient = useApiClient();
  const { id } = useIdentity();

  return useQuery({
    queryKey: ['devices', id, 'attachments'],
    queryFn: async ({ queryKey: [, id] }) => {
      const apiAttachments: ApiAttachment[] =
        await apiClient.public.devices.getAttachments(id || '');

      const attachments = apiAttachments
        .map<Attachment>((attachment) => reviveAttachment(attachment))
        .sort((a, b) =>
          a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()),
        );

      return attachments.reduce<Record<ApiAttachmentCategory, Attachment[]>>(
        (acc, attachment) => {
          switch (attachment.category) {
            case 'energy-declaration-ovk':
              acc['energy-declaration-ovk'].push(attachment);
              break;
            case 'property-info':
              acc['property-info'].push(attachment);
              break;
            case 'restaurant-menu':
              acc['restaurant-menu'].push(attachment);
              break;
            default:
              acc.other.push(attachment);
              break;
          }
          return acc;
        },
        {
          'energy-declaration-ovk': [],
          'property-info': [],
          'restaurant-menu': [],
          other: [],
        } as Record<ApiAttachmentCategory, Attachment[]>,
      );
    },
    enabled: !!id,
    refetchInterval: 30 * 60 * 1000, // 30 minutes
  });
};

const blobToBase64 = async (blob: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result?.toString() || '');
    };
    reader.readAsDataURL(blob);
  });

export const useAttachment = (hash: string): UseQueryResult<string> => {
  const { api } = useEnv();
  const { apiKey } = useIdentity();

  return useQuery<string>({
    queryKey: ['attachments', { hash, apiKey, api }],
    queryFn: async () => {
      const response = await fetch(`${api ?? ''}/public/attachments/${hash}`, {
        headers: {
          'x-api-key': apiKey,
        } as Record<string, string>,
      });
      const blob = await response.blob();
      return await blobToBase64(blob);
    },
    enabled: !!hash,
    staleTime: 4 * 60 * 60 * 1000, // 4 hours
  });
};
