import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DateTime } from 'luxon';

interface Props {
  format?: string;
  date?: boolean;
}

export const Time: React.FC<Props> = ({ format = 'HH:mm', date }) => {
  const localFormat = useMemo(
    () => (date ? 'cccc dd LLL' : format),
    [format, date],
  );

  const [time, setTime] = useState(DateTime.now().toFormat(localFormat));

  const tick = useCallback(() => {
    const nextTime = DateTime.now().toFormat(localFormat);
    setTime((val) => (val.localeCompare(nextTime) === 0 ? val : nextTime));
  }, [localFormat]);

  useEffect(() => {
    const interval = setInterval(tick, 1000);
    return () => clearInterval(interval);
  }, [tick]);

  return <>{time}</>;
};

export default Time;
